@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;700;900&display=swap');

@font-face {
  font-family: "Flaticon";

  src: url("./../../assets/fonts/Flaticon.eot");
  src: url("./../../assets/fonts/Flaticon.eot");
  src: url("./../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("./../../assets/fonts/Flaticon.woff") format("woff"),
    url("./../../assets/fonts/Flaticon.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}


[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
  font-family: Flaticon;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-sketch:before {
  content: "\f100";
}

.flaticon-creativity:before {
  content: "\f101";
}

.flaticon-graphic-design:before {
  content: "\f102";
}

.flaticon-3d:before {
  content: "\f103";
}

.flaticon-development:before {
  content: "\f104";
}

.flaticon-repair:before {
  content: "\f105";
}

.flaticon-coding:before {
  content: "\f106";
}

.flaticon-develop:before {
  content: "\f107";
}

.flaticon-implement:before {
  content: "\f108";
}

.flaticon-sugar-cubes:before {
  content: "\f109";
}

.flaticon-3d-modeling:before {
  content: "\f10a";
}

.flaticon-light-bulb:before {
  content: "\f10b";
}

.flaticon-solution:before {
  content: "\f10c";
}

.flaticon-creativity-1:before {
  content: "\f10d";
}

.flaticon-files-and-folders:before {
  content: "\f10e";
}

.flaticon-infographic:before {
  content: "\f10f";
}

.flaticon-secure:before {
  content: "\f110";
}

.flaticon-bullhorn:before {
  content: "\f111";
}

.flaticon-advertising:before {
  content: "\f112";
}

.flaticon-seo:before {
  content: "\f113";
}

.flaticon-target:before {
  content: "\f114";
}

.flaticon-price-tag:before {
  content: "\f115";
}

.flaticon-branding:before {
  content: "\f116";
}

.flaticon-branding-1:before {
  content: "\f117";
}

.flaticon-rating:before {
  content: "\f118";
}

.flaticon-support:before {
  content: "\f119";
}

.flaticon-technical-support:before {
  content: "\f11a";
}

.flaticon-medal:before {
  content: "\f11b";
}

.flaticon-review:before {
  content: "\f11c";
}

.flaticon-complaint:before {
  content: "\f11d";
}

.flaticon-rating-1:before {
  content: "\f11e";
}

.flaticon-research:before {
  content: "\f11f";
}

.flaticon-analysis:before {
  content: "\f120";
}

.flaticon-laboratory:before {
  content: "\f121";
}

.flaticon-speed:before {
  content: "\f122";
}

.flaticon-optimization:before {
  content: "\f123";
}

.flaticon-experiment:before {
  content: "\f124";
}

.flaticon-results:before {
  content: "\f125";
}

.flaticon-straight-quotes:before {
  content: "\f126";
}

.flaticon-quotation:before {
  content: "\f127";
}

.flaticon-rocket:before {
  content: "\f128";
}

.flaticon-badge:before {
  content: "\f129";
}

.flaticon-value:before {
  content: "\f12a";
}

.flaticon-location:before {
  content: "\f12b";
}

.flaticon-telephone:before {
  content: "\f12c";
}

.flaticon-email:before {
  content: "\f12d";
}


:root {
  --white-color: #ffffff;
  --primary-color: #00CC99;
  --secondary-color: #0066CC;
  --section-bg-color: #f0f8ff;
  --custom-btn-bg-color: #0066CC;
  --custom-btn-bg-hover-color: #00CC99;
  --dark-color: #000000;
  --p-color: #717275;
  --border-color: #7fffd4;
  --link-hover-color: #0066CC;

  --body-font-family: 'Montserrat', sans-serif;
  --title-font-family: 'Sono', sans-serif;

  --h1-font-size: 58px;
  --h2-font-size: 46px;
  --h3-font-size: 32px;
  --h4-font-size: 28px;
  --h5-font-size: 24px;
  --h6-font-size: 22px;
  --p-font-size: 16px;
  --menu-font-size: 14px;

  --border-radius-large: 100px;
  --border-radius-medium: 20px;
  --border-radius-small: 10px;

  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
}

.minusmargin107 {
  margin-top: -107px;
}

.pbdesktop
{
  padding-bottom: 75px;
}


/* Masonry Grid CSS */
.masonry-grid {
  column-count: 4;
  -webkit-column-count: 4;
  -moz-column-count:4;
  column-gap: 20px;
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  margin: 50px 0px;
}
/* contact us */



a.btn-contact {
  color: #000000
}

.btn-contact {
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  /* padding: 16px; */
  border: 1px solid #333;
  z-index: 20;
  transition: all 0.35s cubic-bezier(1, 0, 0, 1);
  color: #000000;
}

.btn-contact:hover {
  color: #fcfcfc;
}

.btn-color {

  color: #101c3d;
}

.btn-color::before,
.btn-color::after {
  background: #101c3d;
}

.btn-contact::before,
.btn-contact::after {
  content: "";
  position: absolute;
  z-index: -1;
  transition: all 0.35s cubic-bezier(1, 0, 0, 1);
}

.btn-l-r::after {
  height: 100%;
  width: 0;
  top: 0;
}

.btn-l-r:hover::after {
  width: 100%;
}

.btn-l-r::after {
  left: 0;
}

h1.whitecolor {
  color: white;
  font-size: 4em;
  font-weight: 700;
  /* letter-spacing: 1px; */
  line-height: 1em;
}

span.block {
  display: block;
  /* line-height: 23px; */
}

.wrapvh {
  height: 65%;
}

.right_conatct_social_icon {
  /* background: linear-gradient(to top right, #1325e8 -5%, #8f10b7 100%); */
  background: none;
}

.contact_us {
  background-color: #f1f1f1;
  padding: 120px 0px 80px 0px;
}

.contact_inner {
  background-color: #fff;
  position: relative;
  box-shadow: 20px 22px 44px #cccc;
  border-radius: 25px;
}

.contact_field {
  padding: 100px 0px 100px 100px;
}

.right_conatct_social_icon {
  height: 100%;
  display: block;
}

.contact_field h3 {
  color: #000;
  font-size: 40px;
  letter-spacing: 1px;
  font-weight: 600;
  margin-bottom: 10px
}

.contact_field p {
  color: #000;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-bottom: 35px;
}

.contact_field .form-control {
  border-radius: 0px;
  border: none;
  border-bottom: 1px solid #ccc;
  /* width: 250px; */
}

.contact_field .form-control:focus {
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid #1325e8;
}

.contact_field .form-control::placeholder {
  font-size: 13px;
  letter-spacing: 1px;
}



.contact_info_sec h4 {
  letter-spacing: 1px;
  padding-bottom: 15px;
}

.info_single {
  margin: 20px 0px;
}

.info_single i {
  margin-right: 15px;
}

.info_single span {
  font-size: 16px;
  letter-spacing: 1px;
  color: #ffffff;
}

button.contact_form_submit {
  background: linear-gradient(to top right, #333 -5%, #000000 100%);
  border: none;
  color: #fff;
  padding: 10px 15px;
  width: 100%;
  margin-top: 25px;
  border-radius: 35px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 2px;
  width: 125px;
}

.socil_item_inner li {
  list-style: none;
}

.socil_item_inner li a {
  color: #fff;
  margin: 0px 15px;
  font-size: 14px;
}
.align-item-center
{
  align-items: center;
}

.socil_item_inner {
  padding-bottom: 10px;
}

.map_sec {
  padding: 50px 0px;
}

.map_inner h4,
.map_inner p {
  color: #000;
  text-align: center
}

.map_inner p {
  font-size: 13px;
}

.map_bind {
  margin-top: 50px;
  border-radius: 30px;
  overflow: hidden;
}

.contact_info_sec h4 {
  color: white !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
  border: none;
}

/* contact us css end */


.mbpara {
  margin-bottom: 5rem;
}

.contact_info_sec {
  /* position: relative; */
  background-color: #000000;
  /* right: 0px;
  top: -36px;
  height: 340px;
  width: 358px; */
  padding: 40px;
  border-radius: 0px 25px 25px 0px;
}

/* 
.webapp li.card {
  max-width: 22rem !important;
} */

.webapp li.card {
  max-width: 38rem !important;
  min-height: 350px;
}

.main-banner {
  padding-top: 180px;
}


.project-details-area .project-info {
  padding: 80px;
  display: inline-block;
  background: #101c3d;
  /* position: absolute; */
  right: 50px;
  bottom: -50px;
  box-shadow: 0 0 10px #cccccc;
  color: #ffffff;
}

.project-details-area .top-info {
  position: relative;
  margin-bottom: 50px;
}

.topbarbg {
  background: #fff !important;
}

/* clound infra page css */

.aqua-main {
  position: relative;
  /* width: calc(min(90rem, 90%)); */
  width: calc(min(109rem, 90%));
  margin: 0 auto;
  min-height: 100vh;
  column-gap: 30rem;
  /* padding-block: min(20vh, 3rem); */
}

.bg {
  position: fixed;
  top: -4rem;
  left: -12rem;
  z-index: -1;
  opacity: 0;
}

.bg2 {
  position: fixed;
  bottom: -2rem;
  right: -3rem;
  z-index: -1;
  width: 9.375rem;
  opacity: 0;
}

.div span {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 1rem;
  color: #717171;
}

.div h1 {
  text-transform: capitalize;
  letter-spacing: 0.8px;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  font-size: clamp(3.4375rem, 3.25rem + 0.75vw, 4rem);
  background-color: #005baa;
  background-image: linear-gradient(45deg, #005baa, #000000);
  background-size: 100%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
}

.div hr {
  display: block;
  background: #005baa;
  height: 0.25rem;
  width: 6.25rem;
  border: none;
  margin: 1.125rem 0 1.875rem 0;
}

.div p {
  line-height: 1.6;
}

.aqua-main a {
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
  color: #717171;
  font-weight: 500;
  background: #fff;
  border-radius: 3.125rem;
  transition: 0.3s ease-in-out;
}

.aqua-main>.div>a {
  border: 2px solid #c2c2c2;
  margin-top: 2.188rem;
  padding: 0.625rem 1.875rem;
}

.aqua-main>.div>a:hover {
  border: 0.125rem solid #005baa;
  color: #005baa;
}

main.aqua-main.pt176 p {
  /* color: #fff !important; */
  font-weight: 400;
}


.swiper-pagination-bullet,
.swiper-pagination-bullet-active {
  background: #fff;
}

.swiper-pagination {
  bottom: -5.75rem !important;
}

.sticky {
  position: fixed !important;
  top: 0 !important;
  width: 100% !important;
  z-index: 1000 !important;
  background: darkblue;
  overflow-y: scroll;
  ;
}

.swiper-slide h2 {
  color: #fff;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.4;
  margin-bottom: 0.625rem;
  /* padding: 0 0 0 1.563rem; */

}

.swiper-slide a {
  /* margin: 1.25rem 1.563rem 3.438rem 1.563rem; */
  margin: 1.25rem 1.563rem 3.438rem .6rem;
  padding: 0.438em 1.875rem;
  font-size: 0.9rem;
}
.section-padding-top-40 {
  background: #fff;
  padding-top: 100px;
}


.swiper-slide a:hover {
  color: #005baa;
}

.swiper-slide div {
  display: none;
  opacity: 0;
  padding-bottom: 0.625rem;
}

.swiper-slide-active div {
  display: block;
  opacity: 1;
}

.swiper-slide--one {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url("./../img/cloud-infra-aqua.jpg") no-repeat 50% 50%/cover;
}

.swiper-slide--two {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url("./../img/aqua-infra-1.jpg") no-repeat 50% 50%/cover;
}


.swiper-slide--three {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url("./../img/cloud-operation.jpg") no-repeat 50% 50%/cover;
}

.swiper-slide--four {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url("./../img/cloud-computing-sml.jpg") no-repeat 50% 50%/cover;
}

.swiper-slide--five {
  background: linear-gradient(to top, #0f2027, #203a4300, #2c536400), url("./../img/cloud-service-sml.jpg") no-repeat 50% 50%/cover;
}

.swiper-3d .swiper-slide-shadow-left,
.swiper-3d .swiper-slide-shadow-right {
  background-image: none;
}

@media screen and (min-width: 48rem) {
  .aqua-main {
    display: flex;
    align-items: initial;
  }

  .bg,
  .bg2 {
    opacity: 0.1;
  }
}



/* dot css */
.hero_area {
  padding: 0px 0 70px;
  position: relative !important;
  z-index: 1;
}

.hero_area .video_wrapper {
  position: relative;
  width: 100%;
  height: 470px;
  right: -100px;
}

.hero_area .video_wrapper .hero_img.hero_img.hero_img1 {
  z-index: 1;
  left: 0;
  top: 0;
  border-radius: 100% 45%;
  width: auto;
  height: 100%;
  position: absolute;
}

.hero_area .video_wrapper .hero_img.hero_img.hero_img2 {
  z-index: -2;
  left: 0;
  top: 0;
  position: absolute;
}

.hero_area .video_wrapper .hero_img.hero_img.hero_img3 {
  z-index: -3;
  right: 0;
  bottom: 0;
  position: absolute;
}

.section_padding_2 {
  padding: 70px 0 40px;
}

.common_block {
  margin-bottom: 30px;
}

.img_wrapper {
  /* height: 570px; */
  left: -50px;
  margin-bottom: 30px;
  position: relative;
  border-radius: 100% 45%;
  overflow: hidden;
}

.heading {
  font-size: 16px !important;
  margin-bottom: 40px;
}

/* .swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: visible !important;
  list-style: none;
  padding: 0;
  z-index: 1;
} */

/* .swiper-wrapper {

  min-height: 470px !important;
} */

.box-item {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.07);
  padding: 10px 30px;
  background-color: #fff;
  border-radius: 40px;
  margin-bottom: 30px;
}

.box-item p {
  margin-bottom: 0px;
}

/* dot css end */
/* contact bg */
html,
body,
.wrapper {
  height: 100%;
  /* overflow-x: hidden !important; */
}

p {
  margin-bottom: 15px;
}

span.bar-1 {
  padding: 0px;
}

span.bar-2 {
  padding: 0px;
}

span.bar-3 {
  padding: 0px;
}

nav.navbar.bootsnav ul.nav>li>a.active {
  color: #333333;
}


/* prespective css */

.newsletter-text {
  border: 2px solid white;
  padding: 50px;
  margin: 0 auto;
  position: relative;
  max-width: 500px;
 
}

.newsletter-bg {
  position: relative;
  background: inherit;
  overflow: hidden;
  width: 70%;
  height: 70%;
  border-radius: 16px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3), 
              0 6px 20px rgba(0, 0, 0, 0.15);
  z-index: 1;
}

.newsletter-bg :before {
  content: '';
  position: absolute;
  background: inherit;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: -20px;
  box-shadow: inset 0 0 500px rgba(255, 255, 255, .4); 
  filter: blur(10px);
  z-index: -1;
}


.perspective-text {
  color: wheat !important;
  font-family: Arial;
  font-size: 38px;
  font-weight: 900;
  letter-spacing: -2px;
  text-transform: uppercase;
}

.perspective-line {
  height: 50px;
  overflow: hidden;
  position: relative;
}

.perspective-line p {
  margin: 0;
  height: 50px;
  line-height: 50px;
  transition: all 0.5s ease-in-out;
}

.perspective-line:nth-child(odd) {
  transform: skew(60deg, -30deg) scaleY(0.667);
}

.perspective-line:nth-child(even) {
  transform: skew(0deg, -30deg) scaleY(1.337);
}

.perspective-text:hover p {
  transform: translate(0, -50px);
}

.perspective-line:nth-child(1) {
  left: 29px;
}

.perspective-line:nth-child(2) {
  left: 58px;
  background: #f07e6e;
}

.perspective-line:nth-child(3) {
  left: 87px;
  background: #84cdfa;
}

.perspective-line:nth-child(4) {
  left: 116px;
  background: #5ad1cd;
}

.perspective-line:nth-child(5) {
  left: 145px;
}

.prespectivebody {
  display: flex;
  /* align-items: center; */
  justify-content: start;
  overflow: visible;
  margin-left: auto;
  margin-right: auto;
  /* margin-top: 5px; */

}

.navbarright104 {
  margin-right: 104px;
}

.icon-screen-smartphone:before {
  content: "\e010";
  /* color: #D00903; */
  font-weight: bolder;
}

/* xrvr css */


.section44 {
  /* background: linear-gradient(to bottom right, #000000, #096092); */
  /* height: 72vh !important; */
  /* font-family: tahoma, sans-serif; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 135px !important;
}

.article44 {
  display: flex;
  border-radius: 50px;
  overflow: hidden;
}

.bild {
  background: url("./../img/gallery/bild-laptop.avif");
  width: 60%;
  background-size: cover;
  background-position: center;
}

.info44 {
  width: 40%;
  background: lightskyblue;
  padding: 5%;
  color: black;
}

.info44 h1 {
  font-size: 30px;
}

.info44 p {
  margin-bottom: 50px;
}

.info44 a {
  text-decoration: none;
  color: black;
  background: #096092;
  padding: 10px 25px;
  border-radius: 15px;
  transition: all 0.5s linear;
}

.info44 a:hover {
  box-shadow: 0px 0px 10px black;
  transition: all 0.5s linear;
}

/* xrvr css end */

/* prespective css end*/
.default-padding {
  padding-top: 10px;
  padding-bottom: 120px;
}

.wave-top {
  position: relative;
  top: -58px;
  left: 0;
  width: 100%;
}

.gradient-bg {
  background: linear-gradient(to right, #FF7F50, #D8BFD8);
}

.tags {
  text-align: left !IMPORTANT;
  line-height: 20px;
  padding-top: 10px;
}

.info h4 {
  text-align: left !IMPORTANT;
  line-height: 20px;
}

.about-icon {
  /* width: 208px;
  height: 76px !important; */
  /* background: #CC51FF; */
  border-radius: 81px !important;
  /* border-radius: 50px; */
  display: inline-block;
  position: relative;
  margin-bottom: 31px;
  line-height: 65px !important;
  text-align: center;
  /* box-shadow: 0px 15px 60px rgba(54,127,255,0.1); */
  font-size: 50px;
  color: #8f10b7;
  margin-right: 25px;
}


.info h4 a {

  color: #666666 !important;
}

.dark-layout .blog-area.blog-standard .single-item.thumb-less .item {

  min-height: 300px;
}

h2.logo.logo-display.white {
  color: white !important;
}

nav.navbar.bootsnav .navbar-brand {
  /* padding: 28px 15px !important; */
  margin-top: 11px;
}

.blog-area .item {
  background: #ffffff;
  box-shadow: none;
  border-radius: 8px;
}

/** anime js css**/

/* float stikcy menu */
.black-color {
  color: #000000 !important;
}

.float-container {
  position: fixed;
  top: 58%;
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  width: auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.float-container a {
  z-index: 99;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 220px;
  height: 55px;
  /* margin-right: -190px; */

  padding: 5px 10px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  color: white;
  margin-bottom: 5px;
  ;

  background-color: #eb690b;
  -webkit-box-shadow: 0 2px 4px #7d7d7d;
  box-shadow: 0 2px 4px #7d7d7d;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;

}

.float-container a:hover {
  margin-right: 0;
  background-color: #c45100;
  -webkit-box-shadow: 0 2px 4px #7d7d7d;
  box-shadow: 0 2px 4px #7d7d7d;
}


/* application devlopment css */

.backsvg {

  background: url('./../../../src/assets/img/outline.png');
  color: #fff !important;
}

.backsvg h2,
.backsvg h4 {
  color: #ffffff !important;
}

.white-color {
  color: #fff !important;
}

.responsive-banner-wrap {
  text-align: center;
  margin: 0;
  padding: 0;
  position: relative;
}

.responsive-banner-wrap>*,
.responsive-banner-wrap img,
.responsive-banner-wrap h2 {
  position: relative;
  z-index: 9;
}

.responsive-banner-wrap h2 {
  margin-bottom: 3%;
  background: transparent;
  padding: 15px;
  margin: 0 0 -3px 0;
  font-weight: lighter;
}

.responsive-banner-wrap svg {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
}

.responsive-banner-wrap img {
  width: 5%;
  margin-top: 3%;
}

.responsive-banner-wrap a {
  text-decoration: none;
  color: #fff;
  text-shadow: 2px 2px #333;
}

.responsive-banner-wrap a:hover {
  text-shadow: 4px 4px #333;
}

.banner-svg {
  fill: #101c3d;
}

.ptop5 {
  padding-top: 164px;
}



.about-area .thumb1 {
  position: relative;
  /* z-index: 1; */
  top: -145px;
}

#about h5 {
  font-size: 1.5em;
  color: #00998c;
}

#about span {
  font-size: 170%;
}

.app.card {
  max-width: 100% !important;
  min-height: 650px;
}


#about .card {
  border-color: rgba(0, 153, 140, .4);
}

/* application dev css end */

/* abot section infographic */
ul.webapp {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 1rem;
  list-style: none;
}

ul.webapp li {
  text-align: center;
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: pointer;
}

ul.webapp li:hover {
  background: linear-gradient(to right, #f4f7ff, #f8f4ff);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Adds a subtle shadow effect to each item */

  color: #333;
  transition: all 0.3s ease;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}

span {
  color: var(--accent-color);
  /* background: #eee; */
  padding: 4px;
  border-radius: 7px;
}

.mt140 {
  margin-top: 100px !important;
}

.card {
  display: flex;
  flex-direction: column;
  /* max-width: 13rem; */
  --iconS: 6rem;
  --iconOffsetX: calc(var(--iconS) /);
  --iconOffsetY: calc(var(--iconS) / 2.5);
  margin-top: var(--iconOffsetY);
  margin-inline: var(--iconOffsetX);
  border-radius: 1rem;

  color: #000;
  background-color: #fff;
  --shadowSurface: 0.5rem 0.5rem 0.5rem rgb(0 0 0 / .25);
  --bgGradient: radial-gradient(circle at bottom right, transparent, rgb(0 0 0 / .1));
  background-image: radial-gradient(circle at calc(var(--iconOffsetX) * -1 + var(--iconS) / 2) calc(var(--iconOffsetY) * -1 + var(--iconS) / 2),
      rgb(0 0 0 / .4) calc(var(--iconS) / 2),
      transparent calc(var(--iconS) / 1.6)),
    radial-gradient(circle at calc(var(--iconOffsetX) * -1 + var(--iconS) / 2) calc(var(--iconOffsetY) * -1 + var(--iconS) / 2),
      var(--accent-color) calc(var(--iconS) / 1.4),
      transparent calc(var(--iconS) / 1.4 + 1px)),
    var(--bgGradient);
  --shadowInsetLight: inset 0.125rem 0.125rem 0.125rem rgb(255 255 255 / .5);
  --shadowInsetDark: inset -0.067rem -0.067rem 0.067rem rgb(0 0 0 / .5);
  /* box-shadow: var(--shadowInsetLight),
    var(--shadowInsetDark),
    var(--shadowSurface); */
  margin-right: 5px;
}

.card .icon {
  width: var(--iconS);
  aspect-ratio: 1;
  margin-top: calc(var(--iconOffsetY) * -1);
  margin-left: calc(var(--iconOffsetX) * -1);
  margin-bottom: calc(var(--iconS) / 4);
  display: grid;
  place-items: center;
  color: var(--accent-color);
  font-size: calc(var(--iconS) / 3);
  background-color: inherit;
  border-radius: 50%;
  box-shadow:
    var(--shadowInsetLight),
    var(--shadowInsetDark);
  background-image: var(--bgGradient);
}

.card .title {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--accent-color);
  text-align: center;

}

.card .content {
  padding: 1rem;
  padding-bottom: 1rem;
  font-size: 0.8em
}

/* infographic cssend */

/* Media queries */
@media screen and (max-width:440px) {
  .float-container .icon:last-child {
    display: none;
  }

  .float-container {
    position: fixed;
    top: auto;
    bottom: 0;

    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;

    width: 100%;

    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: auto;
    -webkit-box-align: auto;
    -ms-flex-align: auto;
    align-items: auto;
  }

  .float-container a.icon {
    right: 0;
    bottom: 0;

    width: 100%;
    margin-right: 0;
    margin-bottom: 0;
    padding: 5px;

    border-radius: 0;
    -webkit-box-shadow: 0 0 0 #7d7d7d;
    box-shadow: 0 0 0 #7d7d7d;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-left: 1px solid darkorange;
    border-right: 1px solid darkorange;
  }
}





/* float  menu css end */

/* infograpic fancy css */
/* #infographic .circle {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  border: 7px double;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 40px;
  position: absolute;
  left: 50%;
  margin: 40px 0;
} */

#infographic .circle {
  width: 320px;
  height: 320px;
  border-radius: 50%;
  border: 7px double;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 40px;
  position: absolute;
  left: 6%;
  margin: 40px 0px;
}

/* #infographic section:nth-child(odd) .circle {
  transform: translateX(-100%);
} */

#infographic section:nth-child .circle {
  transform: translateX(-100%);
}


/* #infographic article {
  max-width: 410px;
  margin-bottom: 20px;
  cursor: pointer;
  left: 50%;
  position: relative;
} */

#infographic article {
  max-width: 754px;
  margin-bottom: 20px;
  cursor: pointer;
  left: 13%;
  position: relative;
}

/* #infographic article header {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
} */

#infographic article header {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 30px;
  border-top-left-radius: 30px;
}

#infographic article header i {
  display: flex;
  font-size: 2em;
  border-radius: 50%;
  background-clip: padding-box;
  padding: 14px;
  transition: transform 0.4s;
  color: #fff;
  border: 16px solid var(--bs-dark);
  margin: -16px 0 -16px -20px;
}

#infographic article:hover header i {
  transform: scale(1.2);
}

#infographic article.active header i {
  transform: none;
}

#infographic article .body {
  background: var(--bgColor);
  padding: 0 20px;
  border-bottom-right-radius: 30px;
  border-bottom-left-radius: 30px;
  max-height: 0;
  transition: max-height 0.5s, padding 0.5s;
  overflow: hidden;
}

#infographic article .body .btn {
  padding: 3px 10px;
  text-transform: uppercase;
}

/* #infographic :nth-child(even) article {
  text-align: right;
  transform: translateX(100%);
} */
/* #infographic :nth-child(even) article header {
  flex-flow: row-reverse;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
} */
/* #infographic :nth-child(even) article header i {
  margin: -16px -20px -16px 0;

} */

#infographic section:nth-child(odd) article.active header {
  border-bottom-left-radius: 0;
}

/* #infographic section:nth-child(even) article.active header {
  border-bottom-left-radius: 0;
} */

#infographic article.active .body {
  padding: 20px;
  max-height: 378px;
  border: 1px solid;
  color: #ffffff;
}

/* 
  Articles Positioning
  Uncomment these for debugging article positions
*/
/*#infographic .circle {
  outline: 96px solid yellow;
}
#infographic article header i {
  opacity: 0.5;
  position: relative;
}
#infographic article header i:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 4px;
  height: 4px;
  transform: translate(-2px, -2px);
  background: blue;
}*/

#infographic article[data-step="1"] {
  margin-left: 6px;
}

#infographic article[data-step="2"] {
  margin-left: 54px;
}

#infographic article[data-step="3"] {
  margin-left: 71px;
}

#infographic article[data-step="4"] {
  margin-left: 81px;
}

#infographic article[data-step="5"] {
  margin-left: 82px;
}

#infographic article[data-step="6"] {
  margin-left: 5px;
}

#infographic article[data-step="7"] {
  margin-left: 54px;
}

#infographic article[data-step="8"] {
  margin-left: 70px;
}

#infographic article[data-step="9"] {
  margin-left: 60px;
}

#infographic article[data-step="10"] {
  margin-left: 21px;
}

#infographic article[data-step="11"] {
  margin-left: 7px;
}

#infographic article[data-step="12"] {
  margin-left: 55px;
}

#infographic article[data-step="13"] {
  margin-left: 73px;
}

#infographic article[data-step="14"] {
  margin-left: 62px;
}

#infographic article[data-step="15"] {
  margin-left: 23px;
}

#infographic article[data-step="16"] {
  margin-left: -32px;
}

header.d-flex.align-items-center.text-success.bg-light.bg-opacity-10.pbox {
  padding: 2px 44px;
}

header.d-flex.align-items-center.text-dark.bg-light.bg-opacity-10.pbox {
  padding: 3px 47px;
}

header.d-flex.align-items-center.text-warning.bg-secondary.bg-opacity-10.pbox {
  padding: 3px 47px;
}

header.d-flex.align-items-center.text-secondary.bg-secondary.bg-opacity-10.pbox {
  padding: 3px 47px;
}

header.d-flex.align-items-center.bg-info.bg-opacity-10.pbox {
  padding: 3px 50px;
}

/* infographic fancy css end */

/* app page accordian css */

#accordion>.card {
  max-width: 1000px;
}

.myaccordion {
  max-width: 900px;
  margin: 50px auto;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
}

.myaccordion>.card,
.myaccordion .card:last-child .card-header {
  border: none;
}

.myaccordion .card-header {
  border-bottom-color: #EDEFF0;
  background: transparent;
}

.myaccordion .fa-stack {
  font-size: 18px;
}

.myaccordion .btn {
  width: 100%;
  font-weight: bold;
  color: #004987;
  padding: 0;
}

.myaccordion .btn-link:hover,
.myaccordion .btn-link:focus {
  text-decoration: none;
}

.project-details-area .top-info {
  margin-bottom: 50px;
  position: relative;
}

.project-details-area .project-info {
  background: #101c3d;
  bottom: -50px;
  box-shadow: 0 0 10px #ccc;
  color: #fff;
  display: inline-block;
  padding: 80px;
  position: absolute;
  right: 50px;
}


.myaccordion li+li {
  margin-top: 10px;
}


.hero-image {
  background-image: url(./../img/im1.png);
  height: 450px;
  width: 100%;
  background-color: #fff;
}

.main-banner {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

/* vision realty css */
.pb-bottom {
  padding-bottom: 100px;
}

.pt176 {
  padding-top: 152px;
  padding-left: 0px;
  padding-right: 0px;
}

.section-padding40 {
  padding-top: 100px;
  padding-bottom: 30px;
}

.about-area2 .about-caption .about-icon {
  width: 80px;
  height: 80px;
  background: #CC51FF;
  border-radius: 50px;
  display: inline-block;
  position: relative;
  margin-bottom: 31px;
  line-height: 80px;
  text-align: center;
  box-shadow: 0px 15px 60px rgba(54, 127, 255, 0.1);
  color: #ffffff;
}

/* vision realty css end */
#example1 {
  background: url(./../img/bg-vision-reaity.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* app page accordian css end */

/* paralex css */

.jumbotron {

  background-size: 100% 100%;
  color: #666;
  margin-bottom: 0;
  background-color: #f8f9fa;
  font-weight: 400;
}

.grid-2 {
  display: grid !important;
  grid-gap: 3rem;
  grid-template-columns: repeat(2, 1fr);
}


.pos-rel {
  position: relative !important;
  z-index: 1 !important;
}

.shape-11 {
  position: absolute !important;
  right: 30px !important;
  z-index: -1 !important;
}

.up-move {
  animation: up-move 5s ease-in-out infinite !important;
}

.up-move {
  animation: up-move 5s ease-in-out infinite;
}

@keyframes up-move {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-30px);
  }

  100% {
    transform: translateY(0px);
  }
}

.small-big {
  animation: small-big 5s ease-in-out infinite;
}


.faq-pic-4::before {
  position: absolute;
  content: '';
  height: 7rem;
  width: 8rem;
  z-index: -1;
  background: #1D2027;
  opacity: 1;
  bottom: -24px;
  right: 63px;
  z-index: 1;
  animation: to-left 5s linear infinite alternate;
}

@keyframes to-left {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-30px);
  }

  100% {
    transform: translateX(0px);
  }
}

.car-run {
  animation: run 3s ease-in-out infinite;
}

.faq-dot {
  position: absolute;
  right: 6px;
  top: 60px;
  z-index: -1;
  width: 223px;
}

.pr-60 {
  padding-right: 6rem;
}

.trans-div {
  background: rgba(255, 0, 0, 0.6);
  color: white;
  padding: 25px;
}

.navbar-scroll {
  background-color: rgba(0, 123, 255, 0.4);
  transition: background-color 0.5s;
}

.heading-left {
  margin-bottom: 60px;
  padding: 0px;
}


.col-lg-6.heading-side {
  padding: 0px;
}

footer.bg-dark.text-light {
  padding: 0px 0px;
}

.service {
  padding: 25px;
  background: #000;
  min-height: 436px;
}
.web3 {
  padding: 0px 0px;
}


.hero_content {
  padding: 5px;
}

.services-details-items {
  padding: 5px;
}

.row.align-items-center1 {
  padding: 5px;
}

.row.fill-viewport.align-items-center {
  padding: 2px;
}

.fill-80-viewport {
  min-height: 80vh;
  padding: 1px;
}

.plpr {
  padding: 0px 25px;
}

.xs-padding-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.pbm0 {
  padding-bottom: 120px;
}

.default-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

.pl {
  padding-left: 0px;
  padding-right: 0px;
}

.ptmobile {
  padding-top: 0px;
}


.about-area h4 {
  display: inline-block;
  margin-bottom: 25px;
  border-radius: 5px;
  color: #f35a38;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 16px;
  position: relative;
  z-index: 1;
  padding-left: 45px;
}

.about-area h4::after {
  position: absolute;
  left: 0;
  top: 50%;
  height: 1px;
  width: 30px;
  background: #f35a38;
  content: "";
  margin-top: -1px;
  display: inline-block;
}

.mt50 {
  margin-top: 120px;
}

.ptslider {
  /* padding-top:100px; */
  height: 100vh;
}

.nav-active {
  background-color: #fff;
  box-shadow: 5px -1px 12px -5px grey;
}


.mobtop50 {
  margin-top: 25px;
}

.mobshow {
  display: none;
}
.service-content h4 {
  text-align: center;
  font-size: 25px !important;
}

.service-content img {

  margin: 0 auto;
}

.realestate {
  background-image: url("../img/gallery/realesate-bg.png");
  background-position: 50% 0;
  background-repeat: repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 123, 255, 0.4);
  background-blend-mode: screen; */
}
.grad-text
{
  background: #000000;
background: linear-gradient(to right, #000000 0%, #23538A 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;

}

li.nav-item {
  display: inline-block !important;
  min-width: 100px;
}
.hardwarengg {
  background-image: url("../img/gallery/hardengg_bg.png");
  background-position: 50% 0;
  background-repeat: repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 123, 255, 0.4);
  background-blend-mode: screen; */
  padding: 100px 25px;
}
.hardwarengg {
  background-position: 50% 50%;

}

.project-info-bg {
  background-position: 50% 50%;

}

.project-info-bg {
  background-image: url("../img/gallery/conclusion_center_bg.jpg");
  background-position: 50% 0;
  background-repeat: repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 123, 255, 0.4);
  background-blend-mode: screen; */
}

.regularbg {
  background-position: 50% 50%;

}

.regularbg {
  background-image: url("../img/gallery/regular-bg.jpg");
  background-position: 50% 0;
  background-repeat: repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 123, 255, 0.4);
  background-blend-mode: screen; */
}

.ideationbg {
  background-position: 50% 50%;

}

.ideationbg {
  background-image: url("../img/gallery/ideationbg.jpg");
  background-position: 50% 0;
  background-repeat: repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  /* height: 100vh; */
  min-height: 500px;
  /* background-color: rgba(0, 123, 255, 0.4);
  background-blend-mode: screen; */
}


.vectorbg {
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}
.vectorbg {
  /* background-image: url("../img/gallery/tech-bg.png"); */
  background-position: 50% 0;
  background-repeat: repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;

  /* background-color: rgba(0, 123, 255, 0.4);
  background-blend-mode: screen; */

  background:
    /* top, transparent red, faked with gradient */
    /* linear-gradient(rgba(0, 0, 0, 0.5),
      rgba(0, 0, 0, 0.5)), */
    /* bottom, image */
    url("../img/gallery/tech-bg.png");
}

.realestateblock {
  /* background-color: rgb(184,134,110, 0.2); */
  /* padding:50px 100px; */
  border-radius: 8px;
}
.realestateblock h2 {
  font-size: 3em;
}
.boxshadowpink
{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  padding: 50px;
}
.realestateblock ul li {
  font-size: 23px;
  line-height: 30px;
  margin-bottom: 20px;
  padding-left: 29px;
  /* font-weight: 600; */
  color: #000000;
  /* text-shadow: 2px 1px 0 #7A7A7A; */
  text-indent: -33px;
}

  .realestateblock ul li
  {
    list-style-type: none;
  }
.cocreate {
  width: 100%;
  /* height: 100vh; */
  background-color: rgba(0, 0, 0);
  background-blend-mode: screen;
}




.marginbottom-xs {
  margin-top: 0px;
}

.centertext {
  position: relative;
  top: 50%
}

/* cocreate ppage css */

.ideation {
  height: 100%;
  display: flex;
  align-items: center;
  min-height: 400px;
}
.ideation h1{
  font-size: 4em;
  font-weight: 700;
  /* letter-spacing: 1px; */
  line-height: 1em;



}
.ideation h4{
  font-size: 2em;
  font-weight: 400;
  /* letter-spacing: 1px; */
  line-height: 1em;

}
.dot p {
  border-top: 2px dashed;
  border-color: black !important;
  margin:0; padding: 30px;
  counter-increment: section;
  position: relative;
}



.dot p:nth-child(even):before {
  /* content: counter(section); */
  right: 100%; 
  margin-right: -20px;
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 20px;
  width: 20px;
  background-color: blue;
  text-align:center;
  color: white;
  font-size: 110%;
}

.dot p:nth-child(odd):before {
  /* content: counter(section); */
  left: 100%; 
  margin-left: -20px;
  position: absolute;
  border-radius: 50%;
  padding: 10px;
  height: 20px;
  width: 20px;
  background-color: blue;
  text-align:center;
  color: white;
  font-size: 110%;
}



.dot p:nth-child(even) {
  border-left: 2px dashed;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  margin-right: 30px; 
  padding-right: 0;
}

.dot p:nth-child(odd) {
  border-right: 2px dashed;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  margin-left: 30px; 
  padding-left: 0;
}

.dot p:first-child {
  border-top: 0;
  border-top-right-radius:0;
  border-top-left-radius:0;
}

.dot p:last-child {
  border-bottom-right-radius:0;
  border-bottom-left-radius:0;
}
.banner-center-margin
{
  margin: 45px 0px;
}
.header-content h1 {
  font-weight: 900;
  color: #ffffff;
  /* text-shadow: 0px 1px 1px #bdbdbd; */
  text-transform: capitalize;
  background: linear-gradient(to right, #833AB4 0%, #ffffff 100%);
  font-size: 4em;
  /* background: linear-gradient(to left, #ededed 80%, #bdbdbd 10%); */
  /* -webkit-text-fill-color: transparent; */
  -webkit-background-clip: text;
  /* background-clip: text; */
  /* background: radial-gradient(circle farthest-side at center center, #F6F8F9 0%, #E5EBEE 30%, #D7DEE3 60%, #F5F7F9 100%); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.header-content p {
  margin-top: 30px;
  color: #ffffff;
  font-size: 1.4em;
  font-weight: 400;
}

span#aitxt {
  font-weight: 900;
}

.header-eight .header-image img {
  width: 100%;
  border-radius: 8px;
  object-fit: cover;
}

.header-eight .primary-btn {
  margin-right: 12px;

  color: var(--primary);
  border: 1px solid transparent;
}

.btn-c {
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  padding: 18px 50px;
  border-radius: 4px;
  border: 1px solid transparent;
  background: #fff;
  color: #000000 !important;
  text-align: center;
  display: inline-block;
}

.btn-c:hover {
  background: #155bd5;

}

.btn-c a:hover {
  color: white;
}

.margin-right15 {
  margin-right: 13px;
}

.single-services {
  padding: 40px 30px;
  border: 1px solid #efefef;
  border-radius: 10px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.single-services:hover {
  box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04),
    0px 8px 16px rgba(96, 97, 112, 0.16);
}

.single-services .service-content h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

.section-title-five {
  text-align: center;
  max-width: 550px;
  margin: auto;
  margin-bottom: 50px;
  position: relative;
  z-index: 5;
}

.services-eight {
  padding: 100px 0;
}

.padding-lf
{
  padding:75px;
}
.section-title-five h2 {
  margin-bottom: 15px;
}

.fw-bold {
  font-weight: 700 !important;
}

.mobmt50 {
  margin-top: 0px;

}

.about-five-content .about-five-tab .nav {
  border: none;
  background-color: #efefef;
  padding: 15px;
  border-radius: 5px;
}

.about-five-content .nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.about-five-content .about-five-tab nav .nav-tabs {
  border: none;
}


.about-five-content .about-five-tab nav button {
  border: none;
  color: var(--dark-1);
  font-weight: 600;
  padding: 0;
  margin-right: 20px;
  position: relative;
  background-color: var(--white);
  padding: 10px 18px;
  border-radius: 4px;
  text-transform: capitalize;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: 0 0;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.about-five-content .about-five-tab .tab-content {
  border: none;
  padding-top: 30px;
}

.about-five-content .about-five-tab nav {
  border: none;
  background-color: var(--light-1);
  padding: 0px;
  border-radius: 5px;
}

.about-five-content p {
  font-size: 1.2em;
}

.about-five-content h2 {
  font-size: 3em;
  /* font-weight: 900; */
}

.section-title-five h2 {
  font-size: 3em;
}

.cocreatebox {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: space-between;
  text-align: center;
  width: 100%;
}

.cocreat-item {
  padding: 15px;
  font-size: 1.5em;
  margin-left: 8px;
  background: #F6F8F9;
  background: linear-gradient(to right, #F6F8F9 0%, #E5EBEE 33%, #D7DEE3 100%);


  width: 100%;
  border-top-right-radius: 9px;
}

.cocreat-item:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.cocreat-item:nth-child(2) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}

.cocreat-item:nth-child(3) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
}


.stackbox {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.stackbox-itme {
  /* width: 100%; */
  margin: 0px 50px;
  justify-content: center;
  display: flex;
  /* flex-wrap: nowrap; */
  flex-direction: column;
  text-align: center;
  /* font-size: 25px; */
}

.stackbox-itme h4 {
  font-size: 1.8em;
  font-weight: 500;
}

.pb-bottom.realestateblock ul li {
  color: white;
  font-weight: 700;
  font-size: 15px;
}
 
ul .newlist
{
  list-style-type: none;
}
.newlist li {
  padding-left: 20px;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 25px;
  padding-left: 37px;
  padding-left: 19px;
  font-weight: 400;
  text-indent: -36px;
  text-indent: -41px;
  
}
.about-img.lftimg img {
  /* box-shadow: 0px 8px 15px 0px rgba(0,0,0,0.1); */
  border-radius: 0px;
  box-shadow: 0px 26px 30px -21px rgba(0,0,0,0.8);
}


@keyframes opacity-animation {
  0% {
    opacity: 0;
  }
}
@keyframes pulse-animation {
  0% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0.8;
  }
  70% {
    opacity: 0;
    transform: translate(-50%, -50%) scale(3);
  }
  to {
    transform: translate(-50%, -50%) scale(0);
    opacity: 0;
  }
}
.pulse:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: #f9e9a2;
  border-radius: 100%;
  opacity: 0.5;
  transform: translate(-50%, -50%) scale(3);
  animation: pulse-animation 3s infinite;
}

.pulse {
  position: relative;
  display: inline-block;
  vertical-align: 1px;
  width: 10px;
  height: 10px;
  margin: 0 6px;
  background: #ffdd40;
  color: transparent;
  border-radius: 100%;
  flex: 0 0 auto;
  animation: opacity-animation 1s linear;
}
.pulse.black {
  background: #000000;
}

.pulse.green {
  background: #000000;
}

/* cocreatep page css end */

/* whitepaper listing page css */

.bg-pattern:before, #pattern_bg:before {
  position: absolute;
  z-index: -1;
  content: "";
  width: 100%;
  height: 600px;
  left: 0;
  top: 0;
  background-image: url(../img/gallery/pattern-bg.png);
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  background-size: cover;
  background-position: center center;
}
.overlay-pr {
  /* background-color: #f2f2f2; */
  background: -webkit-linear-gradient(rgba(242,242,242,0) 0,rgba(242,242,242,1) 10%) repeat scroll 0 0;
  background: -o-linear-gradient(rgba(242,242,242,0) 0,rgba(242,242,242,1) 10%) repeat scroll 0 0;
  background: -moz-linear-gradient(rgba(242,242,242,0) 0,rgba(242,242,242,1) 10%) repeat scroll 0 0;
  background: linear-gradient(rgba(242,242,242,0) 0,rgba(242,242,242,1) 10%) repeat scroll 0 0;
  position: absolute;
  left: 0;
  top: 60px;
  bottom: 0;
  width: 100%;
  height: auto;
}

.content-whitepaper  a
{
  color: #000000;
}
.whitepaper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 5px auto 35px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.top-label {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
}

.content-whitepaper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.text-box {
 
  padding: 20px;
}

.text-box-50 {
 
  padding: 50px;
}

.image-box {

  text-align: center;
}

.whitepaper img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}


.box-whitepaper {

position: relative;
background-color: #ffffff;

}

.title-box-yellow {
background-color: #df9926;
color: #fff;
padding: 4px 8px;
display: inline-block;
position: relative;
font-size: 11px;
letter-spacing: 1px;
text-transform: uppercase;
font-weight: 700;
}
.minus-top-grd, .minus-top-lst {
line-height: 16px;
}
.title-box-yellow:after {
border-left: 0 solid transparent;
border-right: 20px solid transparent;
border-top: 24px solid #df9926;
clear: both;
content: "";
height: 0;
position: absolute;
right: -20px;
top: 0;
width: 0;
}

.relative {
position: relative;
}

 /* Set the banner image as a background with appropriate styles */
 .softdev-banner {
  background-image: url('../img/gallery/whitepaper2.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  color: #fff; /* Set text color to be visible on the image */

  padding: 114px 0; /* Adjust the top and bottom padding as needed */
}


.whitepaper-banner1 {
  background-image: url('../img/gallery/whitepaper_gray_bg_1.png'); /* Replace with your image path */
  background-size: cover;
  background-position: center;
  color: #fff; /* Set text color to be visible on the image */

  padding: 114px 0; /* Adjust the top and bottom padding as needed */
}

/* Add styles for the text on the banner */
.softdev-text {
  font-size: 2rem;
  font-weight: bold;
}

.borderleft {
  border-left: 8px solid #212529 !important;
  margin-left: 4rem !important;
  min-height: 40px;
  padding: 12px;
  margin-bottom: 25px;
}
.highlight {
  font-weight: 600;
  margin-bottom: 1.2rem;
  position: relative;
}
/* whitepaper listing page css end */

/* top codes for desktop */
/*Responsive Masonry Grid CSS */
@media (max-width: 1199px) {
  .masonry-grid {
     column-count: 3;
     -webkit-column-count: 3;
     -moz-column-count: 3; 
  }
}
@media (max-width: 991px){
  .masonry-grid {
     column-count: 2;
     -webkit-column-count: 2;
     -moz-column-count: 2; 
  }
}


@media screen and (min-width: 768px) {

  .basis-md-50 {
    flex-basis: 50%;
  }



  .hero_area .video_wrapper {
    position: relative;
    width: 91%;
    height: 345px;
    right: -9px;
  }

  .mobtop50 {
    margin-top: 50px;
  }


  .section44 {
    /* background: linear-gradient(to bottom right, #000000, #096092); */
    height: 56vh;
    /* font-family: tahoma, sans-serif; */
    display: flex;
    align-items: center;
    justify-content: center;
  }


  .article44 {
    display: flex;
    border-radius: 0px;
    overflow: hidden;
  }

  .ptmobile {
    padding-top: 25px;
  }

  .basis-md-50 {
    flex-basis: 50%;
  }

}

@media(max-width: 768px) {

  .right_conatct_social_icon {
    display: none;
  }

  .contact_info_sec {
    /* position: relative; */
    background-color: #000000;
    /* right: 0px;
      top: -36px;
      height: 340px;
      width: 358px; */
    padding: 40px;
    border-radius: 25px 0 0 25px;
  }



  .webapp li.card {
    max-width: 35rem !important;
  }

  section.module.parallax {
    padding: 34px 0;
  }

  .ptslider {
    padding-top: 8px;
  }

  .plpr {
    padding: 0px 0px;
  }

  .main-banner {
    padding-top: 1px;
  }

  .mt50 {
    margin-top: 5px;
  }

  .aqua-main {

    min-height: 60vh;

  }

  .hero_area {
    padding: 0px 0 70px;

  }


  .bild {
    background: url("../img/gallery/bild-laptop.avif");
    width: 0%;
    background-size: cover;
    background-position: center;
  }

  .section44 {
    /* background: linear-gradient(to bottom right, #000000, #096092); */
    height: 46vh;

  }

  .article44 {

    border-radius: 0px;

  }

  .info44 {
    width: 100%;

  }

  .project-details-area .project-info {

    position: relative;
    right: 0px;

  }

  .project-details-area .project-info {

    position: relative;

  }

  .ptmobile {
    padding-top: 100px;
  }

  .pl {
    padding-left: 30px;
    padding-right: 5px;
  }

  .default-padding {

    padding-bottom: 0px;
  }


  .xs-padding-0 {
    padding-left: 20px;
    padding-right: 20px;
  }


  .section-padding40 {
    padding-top: 10px;

  }

  .main-services-box .item {

    padding: 12px;
    flex-wrap: wrap;
  }

  .navbar {
    background-color: #007bff !important;
  }

  .contact_field {
    padding: 6px 326px 79px 22px;
  }

  .heading-left {
    margin-bottom: 60px;
    padding: 20px;
  }

  .col-lg-6.heading-side {
    padding: 20px;
  }

  .pt176 {
    padding-top: 30px;
    padding-left: 20px !important;
    padding-right: 20px;
  }

  .about-area .about-items .content {
    padding-left: 30px;
  }

  footer.bg-dark.text-light {
    padding: 0px 20px;
  }

  .default-padding {
    padding-top: 60px;
    padding-bottom: 120px;
  }

  .service {
    padding: 0px 45px;
  }

  .web3 {
    padding: 0px 30px;
  }

  .hero_content {
    padding: 25px;
  }

  .hero_area {
    padding: 8px 0 10px;

  }

  .services-details-items {
    padding: 25px;
  }

  .row.align-items-center1 {
    padding: 15px;
  }

  .row.fill-viewport.align-items-center {
    padding: 22px;
  }

  .fill-80-viewport {
    min-height: 80vh;
    padding: 21px;
  }

  .pbm0 {
    padding-bottom: 0;
  }

  .perspective-text {

    font-size: 20px;



  }
}

@media(max-width: 767px) {


  .softdev-banner {
    background-image: url('../img/gallery/whitepaper2.png');
    background-size: cover;
    background-position: right;
    color: #fff;
    padding: 114px 0;
}
  .ideationbg {

    min-height: 450px;
}

.masonry-grid {
     column-count: 1;
     -webkit-column-count: 1;
     -moz-column-count: 1; 
  }

  .banner-center-margin
{
  margin:5px 0px;
}

  .pbdesktop
{
  padding-bottom:5px;
}

  .realestateblock {
    padding: 28px;
  }

  .stackbox-itme {

    margin: 20px 50px;

  }

  .stackbox {

    flex-direction: column;

  }

  .about-five-content p {
    font-size: 1.2em;
    text-align: center;
  }

  .about-five-content h2 {
    font-size: 3em;
    /* font-weight: 900; */
    text-align: center;
  }



  .mobmt50 {
    margin-top: 50px;

  }

  .header-content h1 {

    font-size: 3em;

  }

  .btn-c {
    font-weight: bold;
    font-size: 18px;
    line-height: 18px;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    padding: 12px 25px;
    border-radius: 4px;
    border: 1px solid transparent;
    background: #fff;
    color: #000000 !important;
    text-align: center;
    display: inline-block;
  }

  .marginbottom-xs {
    margin-top: 100px;
  }

  #overlay {
    position: fixed;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
  }

  h1.whitecolor {

    font-size: 2em;

    line-height: 1.5em;
  }

  .whitecolor {
    color: white !important;
  }

  .wrapvh {
    height: auto;
  }

  .vh100w {
    height: 100vh;

  }

  .realestate {
    background-position: 50% 50%;

  }

  .minusmargin107 {
    margin-top: -1px;
  }

  .ptslider {
    padding-top: 50px;
    height: 80vh;
  }

  .mobtop50 {
    margin-top: 50px;
  }

  .contact_field {
    padding: 35px 9px 10px 16px;
  }

  .contact_info_sec {
    /* position: relative; */
    background-color: #000000;
    /* right: 0px;
    top: -36px;
    height: 340px;
    width: 358px; */
    padding: 40px;
    border-radius: 0px 0 0 0px;
  }

  .right_conatct_social_icon {
    display: none;
  }

  .webapp li.card {
    max-width: 35rem !important;
  }

  section.module.parallax {
    padding: 34px 0;
  }

  .ptslider {
    padding-top: 8px;
  }


  .plpr {
    padding: 0px 0px;
  }

  .main-banner {
    padding-top: 0px;
  }

  .mt50 {
    margin-top: 5px;
  }

  .hero_area .video_wrapper {
    position: relative;
    width: 80%;
    height: 470px;
    right: -42px;
  }

  .aqua-main {

    min-height: 60vh;

  }

  .hero_area {
    padding: 0px 0 70px;

  }

  .bild {

    width: 1%;

  }

  .section44 {
    /* background: linear-gradient(to bottom right, #000000, #096092); */
    height: 46vh;

  }


  .article44 {

    border-radius: 0px;

  }

  .info44 {
    width: 100%;

  }

  .about-area h4 {

    padding-left: 0px;
  }

  .about-area h4::after {

    display: none;
  }

  .project-details-area .project-info {

    position: relative;
    right: 0px;

  }

  .project-details-area .project-info {

    position: relative;

  }

  .perspective-text {

    font-size: 20px;

  }

  .pbm0 {
    padding-bottom: 0;
  }

  .ptop5 {
    padding-top: 15px;
  }

  .ptmobile {
    padding-top: 100px;
  }

  .xs-padding-0 {
    padding-left: 20px;
    padding-right: 20px;
  }

  .contact_field {
    padding: 56px 36px 47px 22px;
  }

  .section-padding40 {
    padding-top: 10px;

  }

  .col-lg-6.mtnegative50 {
    margin-top: 52px !important;
  }

  .pt176 {
    padding-top: 30px;
    padding-left: 20px !important;
    padding-right: 20px;
  }

  .heading-left {
    margin-bottom: 60px;
    padding: 20px !important;
  }

  .col-lg-6.heading-side {
    padding: 30px !important;
  }

  .about-area .about-items .content {
    padding-left: 30px;
  }

  footer.bg-dark.text-light {
    padding: 0px 20px;
  }

  .default-padding {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .service {
    padding: 0px 45px;
  }

  .web3 {
    padding: 0px 30px;
  }

  .hero_content {
    padding: 25px;
  }

  .hero_area {
    padding: 8px 0 10px;

  }

  .services-details-items {
    padding: 25px;
  }

  .row.align-items-center1 {
    padding: 15px;
  }

  .row.fill-viewport.align-items-center {
    padding: 22px;
  }

  .fill-80-viewport {
    min-height: 80vh;
    padding: 21px;
  }

  .main-services-box .item {

    padding: 12px;
    flex-wrap: wrap;
  }

  .pl {
    padding-left: 30px;
    padding-right: 5px;
  }
}

/* ============================================================
  PRIMARY STRUCTURE
============================================================ */
.parallax-container {
  max-width: 1020px;
  margin: 0 auto;
}

/* ============================================================
  SECTIONS
============================================================ */
section.module:last-child {
  margin-bottom: 0;
}

section.module h2 {
  margin-bottom: 40px;
  /* font-family: "Roboto Slab", serif; */
  font-size: 30px;
}

section.module p {
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 300;
}

section.module p:last-child {
  margin-bottom: 0;
}

section.module.content {
  padding: 40px 0;
  background-color: rgba(207, 216, 220, 0.5);
}
.media-body h3 {
  font-weight: 600;
}
.mb-100
{
  margin-bottom:150px;
  margin-top: 80px;
}
section.module.content:last-child {
  margin-bottom: 60px;
}

section.module.parallax {
  padding: 240px 0;
  background-position: 0 0;
}

.h1 {
  color: wheat;
  font-size: 48px;
  line-height: 1;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0 0 2px white;
}

/* section.module.parallax-1 {
  background-image: url("https://i.ytimg.com/vi/SwOaV-pzGcA/maxresdefault.jpg");
  background-position: 50% 0;
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(0, 123, 255, 0.4);
  background-blend-mode: screen;
}

section.module.parallax-2 {
  background-image: url("http://www.planwallpaper.com/static/images/Fall-Nature-Background-Pictures.jpg");
  background-position: 50% 0;
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: rgba(0, 123, 255, 0.4);
  background-blend-mode: screen;
}

section.module.parallax-3 {

  background-position: 50% 0;
  background-repeat: repeat;
  background-attachment: fixed;
  background-size: cover;

  background-blend-mode: screen;
} */

@media all and (max-width: 600px) {

  .ptslider {
    padding-top: 8px;
  }


  .main-banner {
    padding-top: 0px;
  }

  .mt50 {
    margin-top: 5px;
  }

  .hero_area .video_wrapper {
    position: relative;
    width: 80%;
    height: 470px;
    right: -42px;
  }

  .aqua-main {

    min-height: 60vh;

  }

  .hero_area {
    padding: 0px 0 70px;

  }


  .bild {
    background: url("https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80");
    width: 0%;
    background-size: cover;
    background-position: center;
  }

  .section44 {
    /* background: linear-gradient(to bottom right, #000000, #096092); */
    height: 46vh;

  }

  .article44 {

    border-radius: 0px;

  }

  .info44 {
    width: 100%;

  }

  .about-area h4 {

    padding-left: 0px;
  }

  .about-area h4::after {

    display: none;
  }

  .project-details-area .project-info {

    position: relative;
    right: 0px;

  }

  .ptmobile {
    padding-top: 100px;
  }

  .xs- .perspective-text {

    font-size: 20px;

  }

  .default-padding {

    padding-bottom: 0px;
  }

  .section-padding40 {
    padding-top: 10px;

  }

  .main-services-box .item {

    padding: 12px;
    flex-wrap: wrap;
  }

  section.module h2 {
    font-size: 42px;
  }

  section.module p {
    font-size: 20px;
  }

  section.module.parallax {
    padding: 34px 0;
  }

  section.module.parallax h1 {
    font-size: 96px;
  }

  .xs-padding-0 {
    padding-left: 30px;
    padding-right: 20px;
  }
}

@media all and (min-width: 960px) {
  .h1 {
    font-size: 160px;
  }
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}


/*modal css start*/
.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06D85F;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.button:hover {
  background: #06D85F;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;
  font-family: Tahoma, Arial, sans-serif;
}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  color: #06D85F;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }

  .popup {
    width: 70%;
  }
}

.heading-wrap .heading:before {
  top: 25px;
  content: "";
  position: absolute;
  left: 0;
  width: 50px;
  height: 4px;
  background-color: #196c35;
}

.heading-wrap .heading {
  position: relative;
  font-size: 50px;
  font-weight: 700;
  padding-left: 100px;
}


/*modal css end*/


* {
  box-sizing: border-box;
}

.container-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  grid-auto-rows: 200px;
  grid-auto-flow: row dense;
  grid-gap: 23px;
  justify-content: center;
}

.card-top,
.card-right,
.card-bottom,
.card-left {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  border-radius: 95.2380952381px;
  box-shadow: 0px 3px 9px 1px rgba(0, 10, 20, 0.2);
  background-color: white;
}

.card-top {
  flex-direction: column;
  grid-column: auto/span 1;
  grid-row: auto/span 2;
}

.card-right {
  flex-direction: row-reverse;
  grid-column: auto/span 2;
  grid-row: auto/span 1;
}

.card-bottom {
  flex-direction: column-reverse;
  grid-column: auto/span 1;
  grid-row: auto/span 2;
}

.card-left {
  flex-direction: row;
  grid-column: auto/span 2;
  grid-row: auto/span 1;
}

.card-image {
  display: flex;
}

.card-top .card-image,
.card-bottom .card-image {
  height: 50%;
  width: 100%;
}

.card-left .card-image,
.card-right .card-image {
  height: 100%;
  width: 50%;
}

.card-image img {
  width: 100%;
  object-fit: cover;
}

.card-top img {
  border-radius: 95.2380952381px 95.2380952381px 0 0;
}

.card-right img {
  border-radius: 0 95.2380952381px 95.2380952381px 0;
}

.card-bottom img {
  border-radius: 0 0 95.2380952381px 95.2380952381px;
}

.card-left img {
  border-radius: 95.2380952381px 0 0 95.2380952381px;
}

.card-text {
  align-self: center;
  padding: 23px;
}

.card-top .card-text {
  height: auto;
  width: auto;
  padding-bottom: 36.8px;
}

.card-right .card-text {
  height: auto;
  width: 50%;
  padding-left: 36.8px;
}

.card-bottom .card-text {
  height: auto;
  width: auto;
  padding-top: 36.8px;
}

.card-left .card-text {
  height: auto;
  width: 50%;
  padding-right: 36.8px;
}

.card-text p {
  margin: 0;
  line-height: 1.35em;
  color: #334455;
}

@media (max-width: 500px) {
  .card-top {
    flex-direction: row;
    grid-column: auto/span 2;
    grid-row: auto/span 1;
  }

  .card-bottom {
    flex-direction: row-reverse;
    grid-column: auto/span 2;
    grid-row: auto/span 1;
  }

  .card-top .card-image,
  .card-bottom .card-image {
    height: 100%;
    width: 50%;
  }

  .card-top img {
    border-radius: 95.2380952381px 0 0 95.2380952381px;
  }

  .card-bottom img {
    border-radius: 0 95.2380952381px 95.2380952381px 0;
  }

  .card-top .card-text {
    height: auto;
    width: 50%;
    padding-right: 36.8px;
  }

  .card-bottom .card-text {
    height: auto;
    width: 50%;
    padding-left: 36.8px;
  }
}

@media (max-width: 400px) {
  .card-image {
    width: 38% !important;
  }

  .card-text {
    width: 100% !important;
  }
}

.parallax-container h4 {
  font-size: 23px;
}
.service .icon {
  font-size: 2.5rem;
  color: #fff;
}

.service h3 {
  color: #fff;
}

.service p {
  color: #fff;
}
.all-btn {
  color: #404DFF;
  text-decoration: underline;
  font-size: 16px;
}

.all-btn:hover {
  color: #404DFF;
  text-decoration: underline;
  letter-spacing: 1px;
}

a:hover {
  color: #000;
}

a:focus,
a:hover {
  text-decoration: none;
}

a,
button {
  color: #fff;
  outline: medium none;
}
.custom-block a {
  color: #000 !important;
}
a,
.button {
  -webkit-transition: .3s;
  -moz-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
}

a:focus,
.button:focus {
  text-decoration: none;
  outline: none;
}

/*paralex css end*/
.bg-trans {
  background: rgba(112, 128, 144, 0.6) !important;
}

/* pills css */

.card-text a {
  color: #666 !important;
}

@media all and (min-width: 600px) {


  section.module h2 {
    font-size: 42px;
  }

  section.module p {
    font-size: 20px;
  }

  section.module.parallax {
    padding: 100px 0;
  }

  section.module.parallax h1 {
    font-size: 96px;
  }
}

@media all and (min-width: 960px) {
  .h1 {
    font-size: 160px;
  }
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}


/*modal css start*/
.box {
  width: 40%;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.2);
  padding: 35px;
  border: 2px solid #fff;
  border-radius: 20px/50px;
  background-clip: padding-box;
  text-align: center;
}

.button {
  font-size: 1em;
  padding: 10px;
  color: #fff;
  border: 2px solid #06D85F;
  border-radius: 20px/50px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.button:hover {
  background: #06D85F;
}

.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  visibility: hidden;
  opacity: 0;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  width: 30%;
  position: relative;
  transition: all 5s ease-in-out;
}

.popup h2 {
  margin-top: 0;
  color: #333;

}

.popup .close {
  position: absolute;
  top: 20px;
  right: 30px;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup .close:hover {
  color: #06D85F;
}

.popup .content {
  max-height: 30%;
  overflow: auto;
}

@media screen and (max-width: 700px) {
  .box {
    width: 70%;
  }

  .popup {
    width: 70%;
  }
}



/*modal css end*/


.container-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 200px));
  grid-auto-rows: 200px;
  grid-auto-flow: row dense;
  grid-gap: 23px;
  justify-content: center;
}

.card-top,
.card-right,
.card-bottom,
.card-left {
  display: flex;
  flex-wrap: nowrap;
  height: 100%;
  width: 100%;
  border-radius: 95.2380952381px;
  box-shadow: 0px 3px 9px 1px rgba(0, 10, 20, 0.2);
  background-color: white;
}

.card-top {
  flex-direction: column;
  grid-column: auto/span 1;
  grid-row: auto/span 2;
}

.card-right {
  flex-direction: row-reverse;
  grid-column: auto/span 2;
  grid-row: auto/span 1;
}

.card-bottom {
  flex-direction: column-reverse;
  grid-column: auto/span 1;
  grid-row: auto/span 2;
}

.card-left {
  flex-direction: row;
  grid-column: auto/span 2;
  grid-row: auto/span 1;
}

.card-image {
  display: flex;
}

.card-top .card-image,
.card-bottom .card-image {
  height: 50%;
  width: 100%;
}

.card-left .card-image,
.card-right .card-image {
  height: 100%;
  width: 50%;
}

.card-image img {
  width: 100%;
  object-fit: cover;
}

.card-top img {
  border-radius: 95.2380952381px 95.2380952381px 0 0;
}

.card-right img {
  border-radius: 0 95.2380952381px 95.2380952381px 0;
}

.card-bottom img {
  border-radius: 0 0 95.2380952381px 95.2380952381px;
}

.card-left img {
  border-radius: 95.2380952381px 0 0 95.2380952381px;
}

.card-text {
  align-self: center;
  padding: 23px;
}

.card-top .card-text {
  height: auto;
  width: auto;
  padding-bottom: 36.8px;
}

.card-right .card-text {
  height: auto;
  width: 50%;
  padding-left: 36.8px;
}

.card-bottom .card-text {
  height: auto;
  width: auto;
  padding-top: 36.8px;
}

.card-left .card-text {
  height: auto;
  width: 50%;
  padding-right: 36.8px;
}

.card-text p {
  margin: 0;
  line-height: 1.35em;
  color: #334455;
}

@media (max-width: 500px) {
  .card-top {
    flex-direction: row;
    grid-column: auto/span 2;
    grid-row: auto/span 1;
  }

  .card-bottom {
    flex-direction: row-reverse;
    grid-column: auto/span 2;
    grid-row: auto/span 1;
  }

  .card-top .card-image,
  .card-bottom .card-image {
    height: 100%;
    width: 50%;
  }

  .card-top img {
    border-radius: 95.2380952381px 0 0 95.2380952381px;
  }

  .card-bottom img {
    border-radius: 0 95.2380952381px 95.2380952381px 0;
  }

  .card-top .card-text {
    height: auto;
    width: 50%;
    padding-right: 36.8px;
  }

  .card-bottom .card-text {
    height: auto;
    width: 50%;
    padding-left: 36.8px;
  }
}

@media (max-width: 400px) {
  .card-image {
    width: 38% !important;
  }

  .card-text {
    width: 100% !important;
  }
}

/* pisslis css end */

/* vision realtiy new section css */

.wantToWork-area {
  position: relative;
  margin-bottom: -153px;
}

.wantToWork-area .wants-wrapper {
  border-radius: 22px;
}

.btn3 {
  background: #F067FF;
  font-family: "Raleway", sans-serif;
  text-transform: capitalize;
  padding: 25px 38px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  font-weight: 400;
  border-radius: 30px;
  line-height: 1;
  -moz-user-select: none;
  letter-spacing: 1px;
  line-height: 0;
  margin-bottom: 0;
  cursor: pointer;
  transition: color 0.4s linear;
  position: relative;
  z-index: 1;
  border: 0;
  overflow: hidden;
  margin: 0;
}

.section-bg2 {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.w-padding2 {
  padding: 51px 33px;
  box-shadow: 0 20px 40px rgba(29, 37, 71, 0.04);
}

.wantToWork-area .wantToWork-caption h2 {
  font-size: 37px;
  line-height: 1;
  margin-bottom: 12px;
  font-weight: 600;
  color: #ffffff;
}

.wantToWork-area .wantToWork-caption p {
  font-size: 17px;
  color: #fff;
  margin-bottom: 29px;
}

.services-details-area.default-padding2.mt-5 {

  position: relative;
  top: -220px;
  z-index: -999;
}

.pt300 {
  padding-top: 300px !important;
}

.services-single-content .h2 {
  color: #232323;
  font-weight: normal;
  line-height: 1.2;
  font-family: 'Quicksand', sans-serif;
  letter-spacing: 0;
  margin: 0;
}

ul .squarelist li:before {
  background-color: #005eb8;
  content: "";
  display: block;
  height: 8px;
  left: 0;
  position: absolute;
  top: 13px;
  width: 8px;
}

/* vision realty new section end */



/* mobileapp deve css */

#info--2 {
  background: rgba(0, 0, 0);
}


.fill-viewport {
  min-height: 100vh;
}

.fill-80-viewport {
  min-height: 80vh;
}

.text-white-70 {
  color: rgba(255, 255, 255, 0.7);
}

.text-white-40 {
  color: rgba(255, 255, 255, 0.4);
}

.text-black-40 {
  color: rgba(0, 0, 0);
}

.text-black-70 {
  color: rgba(0, 0, 0);
}

.list-border-y li {
  border-top: 1px solid;
}

.list-border-y li:last-child {
  border-bottom: 1px solid;
}

.list-border-black-20 li,
.list-border-black-20 li:last-child {
  border-color: rgba(0, 0, 0, 0.2);
}



p.lead.text-white-70 {
  font-size: 15px;
}




mobile app section css end#introduction {
  background: linear-gradient(rgba(100, 181, 246, 1), rgba(0, 0, 0, 0.8)),
    url("http://bit.ly/2fBj6OJ") 0% 0% / cover;
}

#info--3 {
  background: linear-gradient(rgba(100, 181, 246, 0.3), rgba(0, 0, 0, 0.8)),
    url("./../img/other-app-bg.jpg") 0% 0% / cover;
}

.fill-viewport {
  min-height: 100vh;
}

.fill-80-viewport {
  min-height: 80vh;
}

.text-white-70 {
  color: rgba(255, 255, 255);
}

.text-white-40 {
  color: rgba(255, 255, 255);
}



.text-black-70 {
  color: rgba(0, 0, 0, 0.7);
}

.list-border-y li {
  border-top: 1px solid;
}

.list-border-y li:last-child {
  border-bottom: 1px solid;
}

.list-border-black-20 li,
.list-border-black-20 li:last-child {
  border-color: rgba(0, 0, 0, 0.2);
}


/* robo kid css */
.robokid {
  animation: MoveUpDown 6s linear infinite;
  position: relative;
  left: 0;
  bottom: 0;
}

@keyframes MoveUpDown {

  0%,
  100% {
    bottom: 0;
  }

  50% {
    bottom: 40px;
  }
}

/* robo kid css end */
/* mobile app section css end */




/* swipper css */

.testimonial_area .swiper-pagination .swiper-pagination-bullet {
  margin: 0 2px;
  padding: 6px;
  background: #4c55c4;
}

.testimonial_area .swiper-pagination {
  text-align: right;
}

.swiper-container-horizontal>.swiper-pagination-bullets,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 100%;
}

.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}

.testimonial_area p {
  font-size: 17px;
  line-height: 1.5;
  margin-top: 20px;
}

.testimonial_area h5 {
  margin: 20px 0 10px;
}

.testimonial_area .designation {
  font-size: 14px;
  margin: 0;
}

.swiper-slide p {
  color: #999999;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  /* padding: 0px 1.563rem; */
  line-height: 1.6;
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: visible;
  margin-bottom: 0px;
}

/* siwepper css end */

/* 
clound infra css end */

/* service page css owl */


/*---------------------------------------
  CAROUSEL        
-----------------------------------------*/
.hero-section {
  background-image: url('./../../../src/assets/img/templatemo-wave-banner.jpg'), linear-gradient(#348CD2, #FFFFFF);
  background-repeat: no-repeat;
  background-size: 108% 76%;
  background-position: top;
  padding-top: 200px;
}

.owl-carousel {
  text-align: center;
}

.owl-carousel-image {
  display: block;
}

.owl-carousel .owl-item .owl-carousel-verified-image {
  display: inline-block;
  width: 30px;
  height: auto;
  position: relative;
  right: 5px;
}

.verified-image {
  display: inline-block;
  width: 20px;
  height: auto;
}

/* .owl-carousel .owl-item {
  opacity: 0.35;
} */

.owl-carousel .owl-item.active.center {
  opacity: 1;
}

.owl-carousel-info-wrap {
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  text-align: left;
}

.owl-carousel-info {
  background-color: var(--section-bg-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 30px;
}

.badge {
  background-color: var(--custom-btn-bg-color);
  font-family: var(--title-font-family);
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  padding-bottom: 5px;
}

.owl-carousel-info-wrap .social-share,
.team-thumb .social-share {
  position: absolute;
  right: 0;
  bottom: 0;
}

.owl-carousel-info-wrap .social-icon,
.team-thumb .social-icon {
  opacity: 0;
  transition: all 0.3s ease;
  transform: translateX(0);
  padding-right: 20px;
  padding-left: 20px;
}

.owl-carousel .owl-item.active.center .owl-carousel-info-wrap:hover .social-icon,
.team-thumb:hover .social-icon {
  transform: translateY(-100%);
  opacity: 1;
}

.owl-carousel-info-wrap .social-icon-item,
.owl-carousel-info-wrap .social-icon-link,
.team-thumb .social-icon-item,
.team-thumb .social-icon-link {
  display: block;
  margin-bottom: 10px;
  margin-left: auto;
}

.owl-carousel-info-wrap .social-icon-link {
  margin-top: 5px;
  margin-bottom: 5px;
}

.owl-carousel .owl-dots {
  background-color: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  border-radius: var(--border-radius-large);
  display: inline-block;
  margin: auto;
  margin-top: 40px;
  padding: 15px 25px;
  padding-bottom: 7px;
}

.owl-theme .owl-nav.disabled+.owl-dots {
  margin-top: 40px;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: var(--secondary-color);
}

.owl-carousel .owl-dots {
  background-color: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  border-radius: var(--border-radius-large);
  display: inline-block;
  margin: auto;
  margin-top: 40px;
  padding: 15px 25px;
  padding-bottom: 7px;
}

.custom-block {
  border: 2px solid #000000;
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  padding: 30px;
  transition: all 0.3s ease;
  min-height: 291px;
}

h5.mb-1 a {
  color: #000000 !important;
}

h4.mb-2 {
  color: rgba(0, 0, 0);
}

.section-padding {
  padding-top: 100px;
  padding-bottom: 100px;
}

.custom-block-overlay {
  border-color: transparent;
  padding: 0;
}

.custom-block-overlay .custom-block-image {
  margin: auto;
  width: 100%;
  height: 210px;
  transition: all 0.3s;
}

.custom-block-overlay-info {
  padding: 15px 20px 20px 20px;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.badge {
  background-color: #000000;
  font-family: var(--title-font-family);
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  padding-bottom: 5px;
  padding: 10px;
}

.custom-block-overlay .custom-block-image {
  margin: auto;
  width: 100%;
  height: 210px;
  transition: all 0.3s;
}

.custom-block-image {
  border-radius: var(--border-radius-medium);
  display: block;
  width: 112px;
  height: 112px;
  object-fit: cover;
}

.custom-block-info .custom-block-overlay-info a {
  display: inline-block;
  color: var(--primary-color);
  text-decoration: none;
}

.custom-block-overlay {
  border-color: transparent;
  padding: 0;
}

.custom-block-overlay .custom-block-image {
  margin: auto;
  width: 100%;
  height: 210px;
  transition: all 0.3s;
}

.custom-block-overlay:hover .custom-block-image {
  padding: 15px;
  padding-bottom: 0;
}

.custom-block-overlay-info {
  padding: 15px 20px 20px 20px;
}

.section-title {
  background: var(--section-bg-color);
  border-radius: var(--border-radius-large);
  display: inline-block;
  position: relative;
  z-index: 2;
  margin-bottom: 0;
  padding: 10px 25px;
}

/* .section-title-wrap::after {
  content: "";
  background: var(--section-bg-color);
  width: 100%;
  height: 2px;
  position: absolute;
  top: 24%;
  left: 54%;
  transform: translate(-50%, -50%);
  pointer-events: none;
} */

.custom-block:hover {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  border-color: transparent;
  transform: translateY(-3px);
}


/* industries page css */
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: normal;
  align-content: normal;
}

.flex-items:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  box-shadow: inset -5px -1px 4px -5px rgba(114, 89, 105, 0.34);
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #807F7B;
  margin-right: 10px;
}

.flex-items:nth-child(2) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  box-shadow: inset -5px -1px 4px -5px rgba(114, 89, 105, 0.34);
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #807F7B;
  margin-right: 10px;
}

.flex-items:nth-child(3) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  box-shadow: inset -5px -1px 4px -5px rgba(114, 89, 105, 0.34);
  padding: 20px;
  border-radius: 5px;
  border: 2px solid #807F7B;
}

.glitch-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  background-color: #FFFFF8;
}

.glitch {
  position: relative;
  font-size: 33px;
  font-weight: bold;
  color: #B74C18;
  letter-spacing: 3px;
  z-index: 1;
}

.glitch:before,
.glitch:after {
  display: block;
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.8;
}

.glitch:before {
  animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
  color: #0D0F12;
  z-index: -1;
}

.glitch:after {
  animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
  color: #80FF54;
  z-index: -2;
}

@keyframes glitch-it {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translate(-2px, 2px);
  }

  40% {
    transform: translate(-2px, -2px);
  }

  60% {
    transform: translate(2px, 2px);
  }

  80% {
    transform: translate(2px, -2px);
  }

  to {
    transform: translate(0);
  }
}

/* devops page css */


.hero-header {
  position: relative;

  padding-top: 12rem;
  padding-bottom: 6rem;
  /* background: url(assets/img/devops-lead-bg.jpg) center center no-repeat; */
  background-color: rgb(123, 34, 33 / .5);
  background-size: cover;
}

/* .bg-primary {
  background-color: #90bc79 !important;
} */

img.animated.pulse {
  /* animation-duration: 5s; */
}

.slideInRight {
  animation-name: slideInRight;
}

a.btn.circle.btn-md1.btn-theme.effect {
  font-size: 11px;
  line-height: 19px;
  vertical-align: middle;
}

.marauto {
  margin: 0 auto;

}

.pt55 {
  padding-top: 55px;
}

/* stratagy css */
@charset "UTF-8";

:root {
  --color-brand-primary: hsl(var(--color-brand-primary-h), 43%, 43%);
  --color-brand-primary-h: 251;
  --color-brand-primary-s: 43%;
  --color-brand-primary-l: 43%;
  --color-brand-accent: hsl(var(--color-brand-accent-h), 96%, 61%);
  --color-brand-accent-h: 16;
  --color-brand-accent-s: 96%;
  --color-brand-accent-l: 61%;
  --color-brand-accent-bg: hsl(calc(var(--color-brand-accent-h) + 17), 100%, 96%);
  --ratio: 1.4;
  --s-6: calc(var(--s-5) / var(--ratio));
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: calc(1.05rem + 0.333vw);
  --s1: calc(var(--s0) * var(--ratio));
  --s2: calc(var(--s1) * var(--ratio));
  --s3: calc(var(--s2) * var(--ratio));
  --s4: calc(var(--s3) * var(--ratio));
  --s5: calc(var(--s4) * var(--ratio));
  --s6: calc(var(--s5) * var(--ratio));
  font-size: 62.5%;
  line-height: 1.6;
  box-sizing: border-box;
}

@media (min-width: 40.625em) {
  :root {
    --s0: calc(1.25rem + 0.333vw);
  }
}

@media (min-width: 48em) {
  :root {
    --s0: calc(1.4rem + 0.333vw);
  }
}

@media (min-width: 62em) {
  :root {
    --s0: calc(1.6rem + 0.333vw);
  }
}

*,
*:before,
*:after {
  box-sizing: inherit;
}


@media (min-width: 48em) {
  body {
    font-size: 1.5rem;
  }
}

h2,
h3,
ul {
  margin: 0;
}

ul {
  padding: 0;
}

ul li {
  /* Remove li Bullets with zero-width space for accessability */
  list-style-type: none;
}

ul li:before {
  /* content: "â€‹"; */
  /* add zero-width space */
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  position: absolute;
}

.c-section {
  padding: 0 var(--s3);
}

.c-section__title {
  padding: var(--s2) var(--s3) calc(var(--s6) * 1.8);
  margin: 0 calc(var(--s3) * -1);
  color: rgba(0, 0, 0, 0.4) !important;
  font-size: var(--s4);
  font-weight: 900;
  text-align: center;
}

@media (min-width: 40.625em) {
  .c-section__title {
    padding: var(--s2) var(--s3) var(--s4);
  }
}

@media (min-width: 40.625em) {
  .c-section__title {
    display: flex;
    align-items: self-start;
    justify-content: flex-end;
    position: relative;
    text-align: unset;
  }
}

.c-section__title:after {
  content: "";
  display: block;
  background-size: calc(var(--s5) * 3) calc(var(--s5) * 3);
  width: calc(var(--s5) * 3);
  height: calc(var(--s5) * 3);
  background-repeat: no-repeat;
  background-position: center;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(11rem);
  z-index: 1;
  background-image: url("./../img/strategy-right.png");
}

@media (min-width: 40.625em) {
  .c-section__title:after {
    margin-left: auto;
    margin-right: 0;
    transform: translateY(2rem);
  }
}

.c-section span:before {
  content: "";
  display: block;
  position: absolute;
  top: -100%;
  left: 0;
  width: 100%;
  height: 200%;
  transform: skew(0deg, 10deg);
  z-index: -1;
  /* background: var(--color-brand-primary); */
  background-attachment: fixed;

}

.c-section span:after {
  content: "Focus on continuous learning and adaptation";
  display: block;
  font-size: 1.4rem;
  position: absolute;
  font-weight: 350;
  padding: 0 var(--s3);
  color: #999;
  left: 9px;
}

@media (min-width: 40.625em) {
  .c-section span:after {
    font-size: 23%;
  }
}

@media (min-width: 40.625em) {
  .c-section span:after {
    width: 47ch;
  }
}

@media (min-width: 48em) {
  .c-section span:after {
    width: 57ch;
  }
}

@media (min-width: 62em) {
  .c-section span:after {
    width: 67ch;
  }
}

.c-services {
  display: grid;
  grid-gap: var(--s2);
  margin: 0 calc(var(--s3) * -1);
  padding: var(--s6) var(--s3);
  position: relative;
  z-index: 1;
}

@media (min-width: 40.625em) {
  .c-services {
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: minmax(100px, 1fr);
  }
}

@media (min-width: 40.625em) {
  .c-services {
    padding: 0 var(--s3);
  }
}

.c-services:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  /* background: var(--color-brand-accent-bg); */
  width: 100%;
  height: 100%;
  transform: skew(0deg, 10deg);

}

.c-services__item {
  background: #fff;
  padding: calc(var(--s2) - 0.6rem) var(--s1);
  border-radius: 25px;
  box-shadow: 0 7px 20px rgba(100, 28, 2, 0.135);
  transition: all 300ms ease, transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  position: relative;
  z-index: 1;
}

@media (min-width: 40.625em) {
  .c-services__item {
    transform: translateY(-85px);
  }
}

@media (min-width: 48em) {
  .c-services__item {
    transform: translateY(-130px);
  }
}

@media (min-width: 40.625em) {
  .c-services__item:nth-of-type(1) {
    grid-column: 1/span 4;
    grid-row: auto;
  }
}



@media (min-width: 60em) {
  .c-services__item:nth-of-type(2) {
    grid-column: 5/span 8;
    grid-row: auto;
  }
}



@media (min-width: 40.625em) {
  .c-services__item:nth-of-type(3) {
    grid-column: 1/span 4;
    grid-row: auto;
  }
}



@media (min-width: 60em) {
  .c-services__item:nth-of-type(4) {
    grid-column: 5/span 8;
    grid-row: auto;
  }
}



@media (min-width: 40.625em) {
  .c-services__item:nth-of-type(5) {
    grid-column: 1/span 4;
    grid-row: auto;
  }
}



@media (min-width: 60em) {
  .c-services__item:nth-of-type(6) {
    grid-column: 5/span 8;
    grid-row: auto;
  }
}



.c-services__item h3 {
  color: #000000;
  font-size: var(--s1);
  line-height: 1.2;
}

.c-services__item:after {
  /* content: ""; */
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50px;
  height: 50px;
  border-radius: 25px 0;
  cursor: pointer;
  transition: inherit;
  background-color: var(--color-brand-accent);
  background-size: 20px 20px;
  background-repeat: no-repeat;
  background-position: center;

}

.c-services__item p {
  margin-top: var(--s-1);
  font-weight: 400;
  color: #000000;
}

.c-services__item:hover {
  background-color: var(--color-brand-accent);
}

@media (min-width: 40.625em) {
  .c-services__item:hover {
    transform: translateY(-93px);
  }
}

@media (min-width: 48em) {
  .c-services__item:hover {
    transform: translateY(-138px);
  }
}

.c-services__item:hover h3 {
  color: #fff;
}

.c-services__item:hover p {
  color: rgba(255, 255, 255, 0.8);
}

.c-services__item:hover:after {
  background-color: #fff;

}


.img-gradient {
  position: relative;
  z-index: 99;
}

.img-gradient-left::before,
.img-gradient-right::before {
  width: 70%;
  height: 70%;
  padding-bottom: 0px;
}

.img-gradient-right::before {
  left: auto;
  right: -2rem;
}

.img-gradient::before {
  content: "";
  display: block;
  width: 100%;
  padding-bottom: 30%;
  position: absolute;
  left: -2rem;
  z-index: -1;
  opacity: 0.5;
  bottom: 5rem !important;
  background: linear-gradient(45deg, rgb(59, 205, 152), rgb(43, 170, 124));
  border-radius: 0.25rem;
}

.main-banner .right-image {
  margin-left: 120px;
  position: relative;
  z-index: 2;
}


.main-banner .right-image::after {
  position: absolute;
  background-image: url(./../img/banner-right-dec.png);
  width: 378px;
  height: 378px;
  content: '';
  right: -25%;
  z-index: -999 !important;
}

.main-banner .main-button-gradient {
  position: relative;
  z-index: 2;
}



/* blockchain */

#hero {
  width: 100%;
  height: 80vh;
  /* background: url("./../img/he") top center; */
  background-size: cover;
  position: relative;
}


.popup h2 {
  font-size: 20px !important;
  padding-top: 54px !important;
}

.first-letter {
  font-size: 35px;

}



img.img-3d {
  width: 350px !important;
  height: 382px !important;
  margin-top: 55px;
}

.mySwiper2>* {
  background-color: #ffffff !important;
}

#aqua .swiper-slide {
  background-color: #ffffff !important;
}

#ser .swiper-slide {
  background-color: #ffffff !important;
}

div#ser {
  height: 482px !important;
  width: 100%;
}

.swiper-pagination {
  position: absolute;
  text-align: center;
  bottom: -5px !important;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  background-color: var(--white-color) !important;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  /* border-radius: var(--border-radius-large); */
  padding: 15px 25px;
  padding-bottom: 7px;
}

.swiper-slide .owl-carousel-info {
  display: block !important;
  opacity: 1;
  padding-bottom: 0.625rem;
}

#ser .swiper-slide {
  opacity: 0.5;
  width: 266px;
}

#ser .swiper-slide.swiper-slide-active {
  opacity: 1;
  width: 266px;
}

.owl-carousel-info a {
  margin: 1.25rem 1.563rem 3.438rem 1.563rem;
  padding: 0.438em 1.875rem;
  font-size: 14px;
}

.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
  bottom: var(--swiper-pagination-bottom, 8px);
  top: var(--swiper-pagination-top, auto);
  left: 434px;
  width: 250px;
  background-color: var(--section-bg-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 30px;
  width: 206px;
}

ul.link a {
  color: red !important;
}

img.logo.logo-display {
  width: 164px;
}

img.logo.logo-scrolled {
  width: 164px;
}


.col-lg-6.mtnegative50 {
  margin-top: -105px;
}

.topbarbg {
  background-color: white;
}

/* appearance styling */
ul.Skew li {
  font-size: 20px;
  color: black;
  padding: 5px;
  font-weight: 800;
}

ul.Skew li:nth-child(1):before {
  content: "";
  position: absolute;
  width: 35px;
  height: 100%;

  -webkit-transform: skewX(20deg);
  left: -5px;

}

ul.Skew li a {
  padding: 3px 15px 3px 10px;
  text-decoration: none;
  transform: skewX(20deg);
  -ms-transform: skewX(20deg);
  -webkit-transform: skewX(20deg);
  color: black;
}

/* whitepaper 2 css */

.el {
  height: 16px;
  width: 16px;
  background-color: green;
}
.card-grid-blurnew {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-l);
  grid-row-gap: var(--spacing-l);
  max-width: var(--width-container);
  width: 100%;
}
:root{
  --background-dark: #2d3548;
  --text-light: rgba(255,255,255,0.6);
  --text-lighter: rgba(255,255,255,0.9);
  --spacing-s: 8px;
  --spacing-m: 16px;
  --spacing-l: 24px;
  --spacing-xl: 32px;
  --spacing-xxl: 64px;
  --width-container: 1200px;
}
.hero-blur-section{
  align-items: flex-start;
  background:black;
  display: flex;
  min-height: 100%;
  justify-content: center;
 
}

.card-grid-blurnew{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-column-gap: var(--spacing-l);
  grid-row-gap: var(--spacing-l);
  max-width: var(--width-container);
  width: 100%;
}

.card-grid-blurnew {
  grid-template-columns: repeat(4, 1fr);
}

@media(max-width: 767px){

  .section-padding-top-40 {
    background: #000;
    padding-top: 100px;
  }
  .card-grid-blurnew{
    grid-template-columns: repeat(1, 1fr); 
  }

  .card-blur-new__background {

    left: -5px;
    position: absolute;
    right: 21px;
    top: 0;
    transform-origin: center;

}
}
@media(min-width: 540px){
  .card-grid-blurnew{
    grid-template-columns: repeat(2, 1fr); 
  }
}

@media(min-width: 960px){
  .card-grid-blurnew{
    grid-template-columns: repeat(4, 1fr); 
  }
}

.card-blur-new{
  list-style: none;
  position: relative;
  background-color:black;
  flex:auto;
}

.card-blur-new:before{
  content: '';
  display: block;
  padding-bottom: 150%;
  width: 100%;
}

.card-blur-new__background{

  border-radius: var(--spacing-l);
  bottom: 0;
  filter: brightness(0.75) saturate(1.2) contrast(0.85);
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transform-origin: center;
  trsnsform: scale(1) translateZ(0);
  background-color:white;
  transition: 
    filter 200ms linear,
    transform 200ms linear;
}

.card-blur-new:hover .card-blur-new__background{
  transform: scale(1.05) translateZ(0);
}

.card-blur-new-grid-blurnew:hover > .card-blur-new:not(:hover) .card-blur-new__background{
  filter: brightness(0.5) saturate(0) contrast(1.2) blur(10px);
}

.card-blur-new__content{
  left: 0;
  padding: var(--spacing-l);
  position: absolute;
  top: 0;
}

.card-blur-new__category {
  color: #0a1015;
  font-size: 1.5rem;
  margin-bottom: var(--spacing-s);
  /* text-transform: uppercase; */
  font-weight: normal;
}

.overflowy {
  max-height: 315px;
  overflow-y: auto;
  /* height: auto; */
  /* overflow: hidden; */
}
.overflowy::-webkit-scrollbar-track
{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color:transparent;
}

.overflowy::-webkit-scrollbar
{
    width: 12px;
    background-color:transparent;
}

.overflowy::-webkit-scrollbar-thumb
{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: #555;
}
.card-blur-new__heading {
  color: #0a1015;
  font-size: 2rem;
  text-shadow: 2px 2px 20px rgba(0,0,0,0.2);
  line-height: 1.4;
  font-weight: 800;
}
/* whitepaper 2 css end */
/* hardware css */

section#minimal-statistics .card {
  min-height: 350px;
}
.hardware-main  {
  background-position: 50% 50%;

}
.wrap-collabsible:hover {
  background: rgba(256,256,256,0.3);
  border-radius: 8px;
}
h2.whitecolor
{
  color: #ffffff;
}
.hardware-main  {

  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(5, 5, 5, 0.5)),
   url("./../img/gallery/circut-3.png") center center no-repeat;
  
/*  below values are optional */
 	background-attachment:fixed;
	background-size: cover;
	  -webkit-background-size: cover;
	  -moz-background-size: cover;

  /* background: url('https://www.creative-tim.com/cheatsheet/assets/img/header.jpg') no-repeat center center fixed; */
  background-position: 50% 0;
  background-repeat: repeat;
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  /* height: 100vh; */
  background-color: rgba(0, 0, 0, 0.78);
  background-blend-mode: screen;
}
.hardware-relative
{
  position: relative;
}

/* .hardware-main:before {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  background: #000;
} */

.middle-div {
  max-width: 1200px;
  margin: 0 auto;
}


.review-item {
  width: 100%;
  padding: 10px;
  margin: 10px 0px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-shadow: 0px 0px 8px 0px #c0c0c0;
  display: inline-block;
  background-color: #fff;
}



p.mb-2.text-black-40.text-left.mt-10 {
  margin-top: 115px;
}


.hardwarep {

  font-size: 23px;

  margin-bottom: 8px;

}

input[type='checkbox'] {
  display: none;
}

.wrap-collabsible {
  margin: 1.2rem 0;
}

.lbl-toggle {
  display: block;
  font-weight: bold;
  /* font-family: monospace; */
  font-size: 1.5rem;
  /* text-transform: uppercase; */
  /* text-align: center; */
  padding: 1rem;
  color: #DDD;
  /* background: #333333; */
  cursor: pointer;
  border-radius: 7px;
  transition: all 0.25s ease-out;
}

.lbl-toggle:hover {
  color: #FFF;
}

.lbl-toggle::before {
  content: ' ';
  display: inline-block;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid currentColor;
  vertical-align: middle;
  margin-right: .7rem;
  transform: translateY(-2px);
  transition: transform .2s ease-out;
}
.toggle:checked+.lbl-toggle
{
  background-color: #fff;
  color: #000;
}
.toggle:checked+.lbl-toggle::before {
  transform: rotate(90deg) translateX(-3px);
}
.ibpm-nav .nav-link {
  outline: none;
  color: #000000;
  padding: 6px 13px 6px 1px;
  font-size: 18px;
}

.nav-tabs.active, .nav-tabs:focus, .nav-tabs>:hover {
  border: 0;
  outline:none;
  }
  .nav-tabs > .active > a, .nav-tabs > .active > a:hover {
    outline:none;
  }
.nav-tabs {
  border-bottom: 1px solid grey;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  outline:none;
  border: none;
}
.ibpm-nav .nav-link.active {
  color: var(--bs-nav-tabs-link-active-color);
  font-weight: 800;
  outline:none;
}
.collapsible-content {
  max-height: 0px;
  overflow: hidden;
  transition: max-height .25s ease-in-out;
}

.toggle:checked+.lbl-toggle+.collapsible-content {
  max-height: 350px;
}

.toggle:checked+.lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.collapsible-content .content-inner {
  background: rgba(250, 250, 250, 0.2);
  border-bottom: 1px solid rgba(0, 105, 255, .45);
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
  padding: .5rem 1rem;
  color: #ffffff;
}

.collapsible-content p {
  margin-bottom: 0;
}

.connectivity {
  /* background-image: url(http://localhost:3000/static/media/conectivty.ecea00ed04e69f56b10a.png); */
  background-repeat: no-repeat;
  background-position: 623px -165px;
  min-height: 500px;
}


.colate-logo {
  font-family: 'Baumans';
  font-size: 45px;
  letter-spacing: 1px;
}

.blur-section {
  min-height: 100%;
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.blur-card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  width: 100%;
}
@media (max-width: 1000px) {
  .blur-card-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 620px) {
  .blur-card-grid {
    grid-template-columns: repeat(1, 1fr);
  }
}
.blur-card {
  list-style: none;
  position: relative;
  transition: all 0.5s ease-out;
  transform: scale(1.02);
  filter: drop-shadow(-2px 0px 0px #333);
}
.card__bg {
  position: relative;
  height:300px;
  min-width: 250px;
  background-size: cover;
  background-position: center center;
  border-radius: 1rem;
  overflow: hidden;
  transform: scale(1);
  transition: all 0.5s ease-out;
  /* background: linear-gradient(0deg, rgba(147,147,147,0.969625350140056) 11%, rgba(0,0,0,1) 77%); */
}
.card__bg::after {
  content: "";
  background-image: linear-gradient(360deg, black, transparent);
  height: 150px;
  bottom: 0;
  width: 100%;
  position: absolute;
}
.card__content {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 15px;
}
.card__category {
  font-size: 25px;
  /* text-transform: uppercase; */
  font-weight: 600;
  color: #ffffff;
  /* padding-bottom: 15px; */
  /* padding-top: 25px; */
  /* padding-left: 25px; */
}
h3.card__heading {
  font-size: 15px;
  color: #ffffff;
  font-weight: 100;
 
}
.blur-card:hover {
  transform: scale(1.02);
  filter: drop-shadow(2px 0px 0px #333);
}
.blur-card-grid:hover > .blur-card:not(:hover).card {
  filter: brightness(0.8) grayscale(0.6) contrast(1.2) blur(10px);
}

.blur-bg {
  background-position: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  /* background-color: #141414; */
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(5, 5, 5, 0.5)),
url(./../img/gallery/layer-circuit-1.jpg) center center repeat;
border-bottom: 1px solid #333;
}

.bg2
{
  background-image: url(./../img/gallery/automation-moon.jpg);
}

h2.main-title.fw-bold.fsize-75 {
  font-size: 75px;
}
/* hardware css end */

/* mobile nav pure css */
.divforflex {
  display: flex;
  flex-direction: row;
}

.fleft {
  width: 50%;
  /* float: left !important; */
  display: inline-block;
  z-index: 1000;
  position: relative;
  left: 0px;
}

/* .fright {
  width: 19%;

  display: inline-block;

  text-align: right;

  right: -123px;
  position: relative;
  top: -9px;
} */
.fright {
  width: 19%;
  /* float: right !important; */
  display: inline-block;
  /* z-index: 1000 !important; */
  position: absolute;
  text-align: right;
  /* margin-left: 58px; */
  right: 0px;
  /* position: relative; */
  top: 50px;
}

.dashmenu {
  display: block;
  width: 22px;
  height: 4px;
  background-color: #232323;
  margin-bottom: 3px;
  border-radius: 2px;
}

#menu ul {
  margin: 0;
  padding: 0;
}

#menu .main-menu {
  display: none;
}

#tm:checked+.main-menu {
  display: block;
}

#menu input[type="checkbox"],
#menu ul span.drop-icon {
  display: none;
}

#menu li,
#toggle-menu,
#menu .sub-menu {
  border-style: solid;
  border-color: rgba(0, 0, 0, .05);
}

#menu li,
#toggle-menu {
  border-width: 0 0 1px;
}

#menu .sub-menu {
  background-color: #fff;
  border-width: 1px 1px 0;
  margin: 0 1em;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  padding-bottom: 20px;
}

#menu .sub-menu li:last-child {
  border-width: 0;
}

#menu li,
#toggle-menu,
#menu a {
  position: relative;
  display: block;
  color: #000;
  /* text-shadow: 1px 1px 0 rgba(0, 0, 0, .125); */
}

#menu,
#toggle-menu {
  background-color: #fff;
  /* background-color: darkblue; */
}

#toggle-menu,
#menu a {
  padding: 1em 0.9em;
}

#menu a {
  transition: all .125s ease-in-out;
  -webkit-transition: all .125s ease-in-out;
}

#menu a:hover {
  background-color: fff;
  color: #000;
}

#menu .sub-menu {
  display: none;
}

#menu input[type="checkbox"]:checked+.sub-menu {
  display: block;
}

#menu .sub-menu a:hover {
  color: #000;
}

#toggle-menu .drop-icon,
#menu li label.drop-icon {
  position: absolute;
  right: 1.5em;
  top: 1.25em;
}

#menu label.drop-icon,
#toggle-menu span.drop-icon {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  text-align: center;
  background-color: rgba(0, 0, 0, .125);
  text-shadow: 0 0 0 transparent;
  color: rgba(255, 255, 255, .75);
}

#menu .drop-icon {
  line-height: 1;
}

@media only screen and (max-width: 64em) and (min-width: 52.01em) {
  #menu li {
    width: 33.333%;
  }

  #menu .sub-menu li {
    width: auto;
  }
}

@media only screen and (min-width: 52em) {
  #menu .main-menu {
    display: block;
  }

  #toggle-menu,
  #menu label.drop-icon {
    display: none;
  }

  #menu ul span.drop-icon {
    display: inline-block;
  }

  #menu li {
    float: left;
    border-width: 0 1px 0 0;
  }

  #menu .sub-menu li {
    float: none;
  }

  #menu .sub-menu {
    border-width: 0;
    margin: 0;
    position: absolute;
    top: 100%;
    left: 0;
    width: 12em;
    z-index: 3000;
  }

  #menu .sub-menu,
  #menu input[type="checkbox"]:checked+.sub-menu {
    display: none;
  }

  #menu .sub-menu li {
    border-width: 0 0 1px;
  }

  #menu .sub-menu .sub-menu {
    top: 0;
    left: 100%;
  }

  #menu li:hover>input[type="checkbox"]+.sub-menu {
    display: block;
  }
}

/* mobile nav pure css end */

#message {
  color: red;
  font-weight: 400;
  margin-top: 25px;
}

.clearfix {
  clear: both;
}

.swiper-slide img {
  /* display: block; */
  /* width: 100%; */
  height: auto;
  object-fit: cover;
}

.imgboxshadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.main-services-box .item .icon::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  height: 100%;
  width: 100%;
  background: rgba(8, 106, 216, 0.07);
  border-radius: 30px;
  transform: rotate(-45deg);
  transition: all 0.35s ease-in-out;
  border-radius: 50%;
}

.skill-items {
  padding-left: 35px;
}


@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {

  .mobshow {
    display: block;
  }

}