.actionIcon {
    color: gray;
    margin-right: 1rem;
    cursor: pointer;
}

.logOutBtn {
    float: right;
    margin-right: 2rem;
}
.topLogobar {
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.profileList {
    display: block;
    right: 1.8rem;
    font-size: 1.5rem;
}
