body {
    margin: 0;
    padding: 0;
    background: #f5f5f5;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  
/*   input {
    border: 1px solid none !important;
    border-radius: inherit;
    box-shadow: inherit;
    min-height: 50px;
} */
  
  /* .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 800px;
  }
   */
  .card {

    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    border-top: 4px solid #003566;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  /* input {
    padding: 10px;
    border: none;
    background-color: transparent;

  } */
  
  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
  
  .login-button {
    padding: 10px 20px;
    background-color: #003566;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .register-button {
    padding: 10px 20px;
    background-color: #fff;
    color: #333;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    cursor: pointer;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 0;
}
  #custom-button {
    padding: 10px;
    color: white;
    background-color: #003566;

    border-radius: 5px;
    cursor: pointer;
  }
  
  #custom-button:hover {
    background-color: #20c997;
  }
  
  #custom-text {
    margin-left: 10px;
    font-family: sans-serif;
    color: #aaa;
  }
  
  
  @media (max-width: 480px) {
    .card {
      width: 100%;
      max-width: 300px;
    }
  }
  .sidebar-item.social-sidebar li.facebook a {
    background: #3a559f !important;
    border-radius: 5px;
}

.sidebar-item.social-sidebar li.twitter a {
  background: lightskyblue !important;
  border-radius: 5px;
}

.sidebar-item.social-sidebar li.linkedin a {
  background: #0077B5  !important;
  border-radius: 5px;
}

.sidebar-item.social-sidebar li.pinterest a {
  background: lightcoral !important;
  border-radius: 5px;
}


.blgwrapper {
  display: flex;
  position: relative;
  flex-direction: column;
}

.pt222{
  padding-top: 190px !important;
}

main.blog_details_page {
  background-color: #F4F9FF;
  z-index: -2;
}

.blogwrapper{
  height: 400px;
}
.categorySelect {
  height: 4rem;
  font-size: 1.5rem;
}
.leftSideSpace {
  padding-left: 2rem;
}
.blogArea{
  width: 100%;
}