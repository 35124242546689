.test{
    padding: 4px;
    color: red;
}
.row {
    /* overflow-x: auto; */
  }
body{
    display: block !important;
    background-color: #ffffff;
}
html, body, .wrapper {
    height: 100%;
    /* overflow-x: hidden !important; */
}
.dark-layout-home  {
    background:none;
}
.banner-area {
    position: relative;
    overflow: hidden;
    background-color: linear-gradient(to right, #172857, #7046aa);
}

.row-home {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.container-home  {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width:100% !important;
}

.color-black
{
    color: #999999;
}
