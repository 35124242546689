.blogsBanner {
    height: 200px;
    overflow: hidden;
}
.dltBtn {
    margin-left: 8px;
}
.searchInput {
    font-size: 1.7rem;
}
.blogHeading > h4 {
    display: inline-block;
    margin-bottom: 3rem;
    border-radius: 5px;
    color: #f35a38;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
    z-index: 1;
    padding-left: 45px;
    margin-top: 4rem;
}
.blogHeading > h4::after {
    position: absolute;
    left: 0;
    top: 50%;
    height: 1px;
    width: 30px;
    background: #f35a38;
    content: "";
    margin-top: -1px;
}
.loader {
    margin-left: 50%;
    margin-top: 20%;
}