.marStyle {
  padding-top: 138px;
  position: unset;
  /* height: 100vh; */
}
.gridenttext {
  background-image: linear-gradient(to right, #833ab4, #fd1d1d,#fcb045);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* .colorh1{
  font-size:8vw;
  text-align:center;
  padding:0;
  margin:0;
  height:50vh;
  line-height:10vh;

} */

.colorh1 {
  /* text-transform: uppercase; */
  /* background: linear-gradient(to right, #30CFD0 0%, #330867 100%); */
  font-size: 3.8em;
  line-height: 80px;
  font-weight: 900;
  text-align: left;

}
.mySwiper {
  /* background: linear-gradient(to right, #172857, #7046aa); */
  background: #ffffff;
  color: #000;
  position: relative;
}

.swiper-slide {
  text-align: left;
  font-size: 18px;
  /* background: linear-gradient(to right, #172857, #7046aa); */
  /* height: 40rem; */

  /* Center slide text vertically */
  display: flex;

  flex-direction: row;
  flex-wrap: nowrap;
  font-family: 'Quicksand', sans-serif;
  align-content: stretch;
}


/* .flex-item-slider:nth-child(1) {
  display: block;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: auto;
  order: 0;

} */



.flex-item-slider:nth-child(1) {
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  align-self: center;
  flex-direction: column;
  order: 0;
  /* padding-left: 63px; */
  /* align-items: flex-start; */
  justify-content: center;
}

.flex-item-slider:nth-child(2) {
  display: block;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  align-self: auto;
  order: 0;
  margin-top: 5px;
}

.flex-item-slider.no-shrink {
  flex-shrink: 0;
}

.swiper-slide img {
  /* display: block; */
  max-width: 100%;
  /* height: 100vh; */
  /* object-fit: cover; */
}


.swiper-slide h2 {
  font-weight: 700;
  font-size: 2.2em;
  margin-bottom: 25px;
  /* color: white; */
  color: #000;
  line-height: 1.2;
  font-family: 'Quicksand', sans-serif;
  letter-spacing: 0;
  margin-top: 1px;
}

.swiper-slide p {

  /* color: white; */
  color: #000;
  line-height: 1.3;
  font-family: 'Nunito', sans-serif;
  font-weight: 500;
  font-size: 1.4em;
  /* padding-left: 18px; */
  padding-left: 6px;
}




.p25 {
  padding-left:41px;
  padding-right: 35px;
}

svg.svg-inline--fa.fa-arrow-right-long {
  line-height: 54;
  font-size: 20px;
  /* line-height: 45px; */
  right: 14px;
  position: absolute;
  top: 10px;
}


.swiper-button-prev:after,
.swiper-button-next:after {

  color: black;
}


.flex-item-slider.imginfogra {
  vertical-align: middle;
  /* padding: 1px 22px 1px 12px; */
}




@media(max-width: 767px) {

  .swiper-slide img {
    /* display: block; */
    max-width: 100%;
    height: 41vh;
    object-fit: cover;
}
  .colorh1 {
    text-transform: uppercase;
    /* background: linear-gradient(to right, #30CFD0 0%, #330867 100%); */
    /* -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    font-size:35px;
    font-weight: 900;
    line-height: normal;
    text-align: center;
  
  }
  .btn-animation.border.light {
    /* border: 2px solid rgba(255, 255, 255, 1) !important; */
    border: 2px solid rgba(000, 000, 000, 1) !important;
    display: inline-block;
    padding: 4px 34px 4px 5px;
    text-transform: uppercase;
    font-weight: 400;
    position: relative;
    z-index: 1;
    /* color: #ffffff; */
    color: #000000;
    border-radius: 10px;
    font-size: 13px;
}

.swiper-slide h2 {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 15px;
  /* color: white; */
  color: #000000;
  line-height: 1;
  font-family: 'Quicksand', sans-serif;
  letter-spacing: 0;
  margin-top: 6px;
}
  .swiper-slide p {
    /* color: white; */
    color: #000000;
    line-height: 1.6;
    font-family: 'Nunito', sans-serif;
    font-weight: 400;
    font-size: 15px;
    padding-left: 22px;
    margin-bottom: 17px;
  }

  .swiper-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    transition-timing-function: var(--swiper-wrapper-transition-timing-function, initial);
    box-sizing: content-box;
    flex-direction: row;
  }


  .flex-item-slider:nth-child(1) {
    display: block;
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: auto;
    align-self: auto;
    order: 0;
    padding-left: 0px;
  }

  /* svg.svg-inline--fa.fa-arrow-right-long {
    line-height: 54;
    font-size: 19px;

    right: 8px;
    position: absolute;
    top: 17px;
  } */

  svg.svg-inline--fa.fa-arrow-right-long {
    line-height: 54;
    font-size: 17px;
    /* line-height: 45px; */
    right: 8px;
    position: absolute;
    top: 12px;
}

  .p25 {
    padding-left: 31px;
    padding-right: 0px;
  }
}

/* dropdown css */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu a::after {
  transform: rotate(-90deg);
  position: absolute;
  right: 6px;
  top: .8em;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-left: .1rem;
  margin-right: .1rem;
}
/* dropdown css end */
.mobshow{
  display: none ;
}

@media only screen
and (min-device-width : 320px)
and (max-device-width : 480px){ 
  
  .mobshow{
  display: block ;}
  .gridenttext {

    font-size:1.5em;
}

.swiper-slide a {
  /* margin: 1.25rem 1.563rem 3.438rem 1.563rem; */
  margin: 1.25rem 1.563rem 3.438rem 0rem;

}

.marStyle
{
  padding-top: 0px;
}
}
